import React from 'react';
import {
  Card,
  Header,
  Section,
  Content,
  Span,
} from '../../../components/Modals/styles';

const Modal = ({ header, onModalClose, children }) => (
  <div
    className="modal fade bd-example-modal-sm fade-profile-modal"
    id="GroupModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="modal"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-small" role="document">
      <div className="modal-content contracts-modal bg-green rounded border-0">
        <div className="modal-body rounded p-0 m-0">
          <Card className="m-0">
            <Section className="row m-0">
              <Content className="col">
                <Header>
                  {header}
                </Header>
              </Content>
              <Content message className="col-auto">
                <Span>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={onModalClose}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Span>
              </Content>
            </Section>
            {children}
          </Card>
        </div>
      </div>
    </div>
  </div>

);

export default Modal;
