import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { OrdersListWrapper, Line } from '../styles';
import { IconWrapper, Icon } from '../../giggers/styles';
import Constants from '../../../../shared/constants';
import {
  Border, TableHeader, TableValue, TableData, Wrapper,
} from '../../users/styles';
import LoadingCard from '../../LoadingCard';
import CompanyService from '../../../../services/AdminServices/CompanyService';
import Config from '../../../../config';
import { WhiteCard } from '../../../gigger/styles';
import SearchInput from '../../../../components/Input/SearchInput';
import { getFormattedCSVData } from '../../../../shared/utils';
import Pagination from '../../../../components/Pagination/Pagination';

class HiredGiggersTab extends Component {
  companyService = new CompanyService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      searchbar: true,
      meta: {
        cursor: {
          previous: null,
          current: 0,
          next: 1,
          count: null,
          pageNumber: 1,
          pageSize: 15,
          pages: 1,
        },
      },
      searchQuery: '',
      loading: false,
      hiredgiggers: [],
      csvDownloadData: [],
      csvExportFileds: ['full_name', 'created_at', 'toDate', 'accountOwner', 'user_id', 'gigger_id'],
    };
  }

  componentDidMount() {
    this.fetchHiredGiggers();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        cursor: { pageNumber },
      },
    } = this.state;
    const {
      meta: {
        cursor: { pageNumber: prevStatePageNumber = 1 },
      },
    } = prevState;
    if (prevStatePageNumber !== pageNumber) {
      this.fetchHiredGiggers();
    }
  }

  /**
   *
   * Handles pagination
   * @param {Number} pageNumber page number
   *
   */
  handleGotoPage = (pageNumber) => {
    const { meta: { cursor } } = this.state;
    this.setState({
      meta: {
        cursor: {
          ...cursor,
          pageNumber,
        },
      },
    });
  };

  /**
   *
   * Handles search functionality
   * @param {String} value search text
   *
   */
  handlesearchhiredGiggers=(value) => {
    this.setState({ searchQuery: value, searchbar: value.length <= 0 }, () => {
      this.fetchHiredGiggers(value);
    });
  }

  /**
   *
   * Handles Change in search value
   * @param {Event} event search value
   *
   */
  handleSearchOnChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  /**
   *
   * displays search bar
   *
   */
  openSearchBar = () => {
    this.setState({ searchbar: false });
  };

  /**
   *
   * @async
   * @function handleDownloadCsv
   * Dowloads data in the form of csv file
   *
   */
  handleDownloadCsv = async () => {
    this.setState({ loading: true });
    const { companyId } = this.props;
    const { csvExportFileds } = this.state;
    const queryParams = [
      ['export', true],
      ['exportFields', csvExportFileds.toString()],
    ];

    try {
      const response = await this.companyService.getHiredGiggers(companyId, queryParams);
      this.setState({ csvDownloadData: getFormattedCSVData(response.data), loading: false });
      if (response) {
        this.hiredgiggerCsv.link.click();
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   *
   * @async
   * @function fetchHiredGiggers Fetches hired giggers
   * @param {String} searchInput search text
   *
   */
  async fetchHiredGiggers(searchInput) {
    const {
      meta: {
        cursor: { pageNumber, pageSize },
      },
      searchQuery,
    } = this.state;
    this.setState({ loading: true });
    const queryParams = [
      ['pageNumber', searchInput ? 1 : pageNumber],
      ['pageSize', pageSize],
      ['search', searchQuery],
    ];
    const { companyId } = this.props;
    try {
      const response = await this.companyService.getHiredGiggers(companyId, queryParams);
      const { data = [], meta = {} } = response;
      this.setState({ loading: false, hiredgiggers: data, meta });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      searchbar, csvDownloadData, loading, hiredgiggers, searchQuery, meta,
    } = this.state;
    return (
      <>
        <CSVLink
          data={csvDownloadData}
          filename="hiredGiggers.csv"
          className="d-none"
          ref={(r) => { this.hiredgiggerCsv = r; }}
        >
          click
        </CSVLink>

        <OrdersListWrapper>

          <IconWrapper className="row m-0">

            {searchbar ? (
              <Icon src={Constants.icons.SearchIcon} alt="icon" onClick={this.openSearchBar} />
            ) : (
              <div className="text-end col-auto p-0">
                <SearchInput
                  onSearch={this.handlesearchhiredGiggers}
                  onChange={this.handleSearchOnChange}
                  value={searchQuery}
                />
              </div>
            )}

            <Icon
              src={Constants.icons.ExportIcon}
              alt="icon"
              className={hiredgiggers && hiredgiggers.length > 0 ? 'mr-3' : 'disableOption mr-3'}
              onClick={this.handleDownloadCsv}
            />

          </IconWrapper>

          {loading && (
            <OrdersListWrapper>
              <LoadingCard />
            </OrdersListWrapper>
          )}

          {!loading && (
            <>
              <Wrapper className="row m-0">

                <TableData className="col ">
                  <TableHeader>
                    <FormattedMessage id="common_user" defaultMessage="User" />
                  </TableHeader>
                </TableData>

                <TableData className="col ">
                  <TableHeader>
                    <FormattedMessage id="common_hired" defaultMessage="Hired" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_from" defaultMessage="From" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_to" defaultMessage="To" />
                  </TableHeader>
                </TableData>

                <TableData className="col">
                  <TableHeader>
                    <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                  </TableHeader>
                </TableData>

              </Wrapper>

              {hiredgiggers.length > 0 ? (
                <>

                  {hiredgiggers.map((data) => (
                    <>
                      <Border />
                      <Wrapper className="row m-0">
                        <TableData className="col ">
                          <TableValue>{data.company_user_name ? data.company_user_name : '-'}</TableValue>
                        </TableData>

                        <TableData className="col text-truncate" title={data.gigger_name}>
                          <Link
                            to={{
                              pathname: `/admin/portal/gigger/${data.gigger_id}/profile`,
                              query: { id: data.gigger_id },
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Line>
                              <TableValue>{data.gigger_name}</TableValue>
                            </Line>
                          </Link>
                        </TableData>

                        <TableData className="col">
                          <TableValue>{data.from_date}</TableValue>
                        </TableData>

                        <TableData className="col">
                          <TableValue>{data.to_date}</TableValue>
                        </TableData>

                        <TableData className="col ">
                          <TableValue>{data.accountOwner === null ? '-' : data.accountOwner}</TableValue>
                        </TableData>
                      </Wrapper>
                    </>

                  ))}
                </>
              ) : (
                <>

                  <Border />
                  <WhiteCard className="my-3">
                    {searchQuery && searchQuery.length > 0 ? (
                      <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
                    ) : (
                      <FormattedMessage
                        id="admin_company_no_hired_giggers_found"
                        defaultMessage="No hired giggers found"
                      />
                    )}
                  </WhiteCard>

                </>
              )}
            </>
          )}

        </OrdersListWrapper>

        {!loading && meta.cursor.pages > 1 && (
        <OrdersListWrapper className="bg-transparent">
          <Pagination cursor={meta.cursor} gotoPage={this.handleGotoPage} />
        </OrdersListWrapper>
        )}

      </>
    );
  }
}

export default HiredGiggersTab;
