import React, { Component } from 'react';
import validator from 'validator';
import { FormattedMessage } from 'react-intl';
import {
  FlexContainer,
  Div,
  ContentWrapper,
  OpenModal,
  Heading,
  UploadWrapper,
  CloseButton,
  UploadButton,
  UploadCondition,
  ProfileSubHeading,
  FormFieldWrapperLeft,
  FormFieldWrapperRight,
  FormLeft,
  FormRight,
  ProfileImage,
  Wrapper,
  EditButton,
  SubHeader,
  DragAndDrop,
  JuniorLevelTag,
  ExperienceLevelTag,
  MidLevelTag,
  Name,
  CharactersLeft,
  Description,
  UpperPart,
  DetailsWrapper,
  HourlyRateSection,
  Text,
  UploadDocument,
  NavContent as Anchor,
} from './styles';
import { TextDiv, Label } from '../../components/Input/styles';
import { Border, WhiteButton } from '../customer/styles';
import { Image, Row, Span } from '../../styles/CommonStyles';
import Card from '../../components/Card/Cards';
import { Card as StyledCard } from '../../components/Card/styles';
import Constants from '../../shared/constants';
import { DefaultProfile } from '../../styles/Images';
import TextBox from '../../components/Input/TextBox';
import Select from '../../components/Input/Select';
import TextArea from '../../components/Input/TextArea';
import MultiSelect from '../../components/Input/MultiSelect';
import RadioButton from '../../components/Input/RadioButton';
import Button from '../../components/Button/Button';
import Modal from '../../components/Card/ProfileModal';
import Loader from '../../components/Loader/Loader';
import Document from '../../components/Document';
import CustomDatePicker from '../../components/CustomCalender/CustomDatePicker';
import UploadCvModal from './UploadCVModal';
import {
  getLanguages, getCities, parseData, parseAndTranslateData, isCvVerificationUser,
} from '../../shared/utils';
import AccountService from '../../services/AccountService';
import Config from '../../config';
import ProfilePageService from '../../services/ProfilePageService';
import { FilterButton } from '../../components/Filter/styles';
import { OrderServicesHeader } from '../gigger/styles';
import { ButtonName } from '../../components/Button/styles';
import FeedbackService from '../../services/AdminServices/FeedbackService';
import Toast from '../../components/Toast/Toast';
import SuccessModal from './Modal';
import CheckBox from '../../components/Input/CustomCheckbox';
import Colors from '../../styles/Colors';

class ProfileForm extends Component {
  accountService = new AccountService(Config.apiBase);

  feedbackService = new FeedbackService(Config.apiBase);

  profilePageService = new ProfilePageService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      profileloading: false,
      languagesOptions: getLanguages(),
      originalLangOptions: getLanguages(),
      cityOptions: getCities(),
      hourlyRateOptions: Constants.options.hourlyRateOptions,
      experienceOptions: Constants.options.experienceOptions,
      jobTitleOptions: [],
      categoryOptions: [],
      skillsOptions: [],
      systemsOptions: [],
      originalSkillOptions: [],
      originalSystemsOptions: [],
      isSaveDisabled: false,
      isDisabled: true,
      profileUnfilledErr: false,
      data: {
        firstName: '',
        lastName: '',
        phone: '',
        profilePic: '',
        id: '',
        jobTitle: '',
        category: '',
        summary: '',
        skills: '',
        systems: '',
        languages: '',
        yearsOfExperience: '',
        hourlyRate: '',
        previousJobs: [],
        education: [],
        docs: {
          cv: '',
          personal_letter: '',
          other_docs: [],
        },
        desiredCities: '',
        workAvailabilityDate: '',
        profileViews: '',
        characterCount: 0,
        cvDocs: [],
        personalDocs: [],
        otherDocs: [{ category: 'OtherDocs' }],
        workAvailability: '',
        review: false,
      },
      formValid: {
        phone: '',
        jobTitle: '',
        category: '',
        skills: '',
        systems: '',
        summary: '',
        languages: '',
        yearsOfExperience: '',
        hourlyRate: '',
        workAvailability: '',
        availableDate: '',
        desiredCities: '',
        cv: '',
        firstName: '',
        lastName: '',
        email: '',
      },
      imageSizeError: false,
      uploadImageError: false,
      errorFields: [],
      modalData: {},
      modalIndex: 0,
      showModal: '',
      edit: false,
      loading: false,
      feedbacks: [],
      showToast: false,
      toastData: {
        type: '',
        delay: Constants.toast.LONG_DELAY,
        message: '',
      },
      successModel: false,
      gradesData: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
      ],
      assignmentOptions: [
        { label: Constants.language.label_novare, value: 'novare' },
        { label: Constants.language.label_competitor, value: 'competitor' },
        { label: Constants.language.label_own_contact, value: 'own_contact' },
      ],
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    if (data) {
      this.setState({ data });
    }
    this.fetchOptions();
  };

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;
    if (data !== prevProps.data) {
      this.updateProfile(data);
    }
  };

  /**
   * Updates the form when any change in data occurs.
   * @param {Object} data Profile data
   */

  updateProfile = async (data) => {
    const { stopLoader } = this.props;
    this.setState({ data, loading: true });
    await this.parseData();
    this.setState({ loading: false }, stopLoader());
  };

  /**
   * Fetches all the filters
   */
  fetchOptions = async () => {
    this.setState({ loading: true });
    const { data } = this.props;

    try {
      let filterList = await this.accountService.getAllFilter();
      filterList = filterList.data;
      this.setState({
        data,
        jobTitleOptions: filterList.jobtitle,
        categoryOptions: filterList.category,
        skillsOptions: filterList.skills,
        systemsOptions: filterList.systems,
        originalSkillOptions: filterList.skills.map((skill) => (
          { ...skill, status: Constants.status.APPROVED })),
        originalSystemsOptions: filterList.systems.map((system) => (
          { ...system, status: Constants.status.APPROVED })),
      }, this.parseData);
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  /**
   * Handles parsing of data
   */

  parseData = () => {
    const { data } = this.props;
    const {
      systems, skills, desiredCities, languages, education = [], previousJobs = [],
    } = data;
    const {
      cityOptions, languagesOptions, originalSystemsOptions, originalSkillOptions,
    } = this.state;
    data.systems = parseData(originalSystemsOptions.slice(), systems).values;
    data.skills = parseData(originalSkillOptions.slice(), skills).values;
    data.desiredCities = parseAndTranslateData(cityOptions, desiredCities).values;
    data.languages = parseAndTranslateData(languagesOptions, languages).values;
    const sortedPreviousJobs = previousJobs.length > 0
      ? previousJobs.sort((a, b) => (a.start_date < b.start_date ? 1 : -1)) : [];
    const sortedEducation = education.length > 0
      ? education.sort((a, b) => (a.start_date < b.start_date ? 1 : -1)) : [];
    data.previousJobs = sortedPreviousJobs;
    data.education = sortedEducation.map((obj) => {
      const startYear = obj.start_date ? obj.start_date.toString().split('-')[0] : '';
      const endYear = obj.end_date ? obj.end_date.toString().split('-')[0] : '';
      return { ...obj, start_date: startYear, end_date: endYear };
    });
    this.setState({
      data,
      cityOptions: parseAndTranslateData(cityOptions, desiredCities).options,
      languagesOptions: parseAndTranslateData(languagesOptions, languages).options,
      skillsOptions: parseData(originalSkillOptions.slice(), skills).options,
      systemsOptions: parseData(originalSystemsOptions.slice(), systems).options,
    });
  }

  /**
   * Handles on key press event
   * @param {Event} evt
   */
  handleKeypress = (evt) => {
    const theEvent = evt || window.event;
    const { name } = theEvent.target;
    const { value } = theEvent.target;
    if (name === Constants.phone && !validator.matches(value.toString(),
      Constants.phoneNumberRegexPattern)) {
      theEvent.preventDefault();
      this.setState({ [name]: value }, this.validate);
    }
    if ((name === Constants.from || name === Constants.to)
      && value.length >= Constants.dateLength) {
      theEvent.preventDefault();
    }
  };

  /**
   * Handles change in input field
   * @param {Event} event
   */
  handleChange = (event) => {
    const { name = '', value = '' } = event.target;
    const { data, errorFields } = this.state;
    if (name === Constants.phone) {
      if (validator.matches(value.toString(), /^[0-9 ()+-]*$/)) {
        data[name] = value;
      }
    } else {
      data[name] = value;
    }
    errorFields.filter((o) => o !== name);
    if (name === Constants.inputFields.summary) {
      data.characterCount = value.length;
    }
    this.setState({ data, errorFields }, this.validateForm);
  };

  /**
   * Handles change in select input field
   * @param {String} name
   * @param {String} value
   */
  handleSelectChange = (name, value) => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [name]: value,
      },
      errorFields: prevState.errorFields.filter((o) => o !== name),
    }), this.validateForm);
  };

  /**
   * Handles change in availabity date
   * @param {Number} index Index value of availability date
   */
  handleAvailabilityDates = (index) => (name, value) => {
    const { errorFields, data } = this.state;
    const { workAvailabilityDate } = data;
    workAvailabilityDate[index][name] = value;
    if (workAvailabilityDate[index].from !== '' && workAvailabilityDate[index].to !== '') {
      if (workAvailabilityDate[index].from > workAvailabilityDate[index].to) {
        workAvailabilityDate[index].error = true;
      } else {
        workAvailabilityDate[index].error = false;
      }
    }
    errorFields.filter((o) => o !== name);
    data.workAvailabilityDate = workAvailabilityDate;
    this.setState({ data, errorFields }, this.validateForm);
  };

  /**
   * Handles change in work availabity
   * @param {String} value Eg: Full time,Part time
   */
  handleworkAvailability = (value) => () => {
    const { data } = this.state;
    data.workAvailability = value;
    this.setState({ data }, this.validateForm);
  };

    /**
   * Handles change in former novare consultant
   * @param {String} value Eg: Yes, No
   */
    handleFormerNovreConsultant = (value) => () => {
      const { data } = this.state;
      data.formerNovareConsultant = value;
      this.setState({ data }, this.validateForm);
    };

    handleNegotiatedFee = () => {
      const { data = {} } = this.state;
      const { negotiatedFee = false } = data;
      data.negotiatedFee = !negotiatedFee;
      this.setState({ data }, this.validateForm);
    };

  /**
   * Helps to add more availabity dates
   */
  handleAddMoreDates = () => {
    const { data } = this.state;
    const { workAvailabilityDate } = data;
    if (workAvailabilityDate.length < 6) {
      workAvailabilityDate.push({ from: '', to: '' });
    }
    data.workAvailabilityDate = workAvailabilityDate;
    this.setState({ data }, this.validateForm);
  };

  /**
   * Removes the choosed index of work availability date
   * @param {Number} value Index value of the work availabity date.
   */
  handleRemoveWorkAvailability = (value) => () => {
    const { data } = this.state;
    let { workAvailabilityDate } = data;
    workAvailabilityDate = workAvailabilityDate.filter((obj, index) => index !== value);
    data.workAvailabilityDate = workAvailabilityDate;
    this.setState({ data }, this.validateForm);
  };

  /**
   * Opens a pop up to add more details about previous jobs or education
   * @param {String} modalName name of the modal to get opened.
   */
  handleAddData = (modalName) => () => {
    this.setState({ edit: false, showModal: modalName }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens modal
   * @param {String} modalName name of the modal
   * @param {Object} modalData data to be displayed inside modal
   * @param {Number} modalIndex index value of the data
   */
  handleModalOpen = (modalName, modalData = {}, modalIndex = 0) => () => {
    this.setState({
      edit: true, showModal: modalName, modalData, modalIndex,
    }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens a pop up to upload cv document.
   */
  handleUploadCV = () => {
    this.setState({ showModal: Constants.inputFields.cv }, () => {
      this.openCvElement.click();
    });
  };

  /**
   * Closes opened modals
   */
  handleModalClose = () => {
    this.setState({
      showModal: '',
      modalData: {},
      modalIndex: 0,
    });
  };

  /**
   * Redirects to a tutorial page which explains how to export cv from linkedIn profile.
   */
  handleExportCVTutorial = () => {
    window.open(Constants.routes.linkedInExportCvTutorial.url);
  };

  /**
   * Collects and saves the entered education details from the modal
   * @param {Object} details Education details
   */
  handleCollectEducationData = (details) => {
    const { data, modalIndex, edit } = this.state;
    if (edit) {
      data.education[modalIndex] = details;
    } else {
      data.education.push(details);
    }
    const { education = [] } = data;
    data.education = education.sort((a, b) => (a.start_date < b.start_date ? 1 : -1));
    this.setState({ data }, this.validateForm);
  };

  /**
   * Collects and saves the entered previous jobs from the modal
   * @param {Object} details Previous job details
   */
  handleCollectPreviousJobsData = (details) => {
    const { data, modalIndex, edit } = this.state;
    const { previousJobs } = data;
    if (edit) {
      data.previousJobs[modalIndex] = details;
    } else {
      previousJobs.push(details);
      data.previousJobs = previousJobs;
    }
    this.setState({ data }, this.validateForm);
  };

  /**
   * Handles remove in education details
   * @param {Number} id Index value of the education details
   */
  handleRemoveEducation = (id) => {
    const { data } = this.state;
    let { education = [] } = data;
    education = education.filter((o, index) => index !== id);
    data.education = education;
    this.setState({ data }, this.validateForm);
  };

  /**
   * Handles remove in previous jobs
   * @param {Number} id Index value of the previous jobs
   */
  handleRemovePreviousJob = (id) => {
    const { data } = this.state;
    let { previousJobs = [] } = data;
    previousJobs = previousJobs.filter((o, index) => index !== id);
    data.previousJobs = previousJobs;
    this.setState({ data }, this.validateForm);
  };

  /**
   * Updates the data gathered from cv to form.
   * @param {Object} cvdata Data gathered by uploading cv.
   */
  handleUpdateCV = (cvdata) => {
    this.setState({ loading: true });
    this.handleModalClose();
    const { data } = this.state;
    let {
      skillsOptions, experienceOptions, jobTitleOptions, languagesOptions, systemsOptions,
    } = this.state;
    const {
      docs, cvDocs, previousJobs: jobData, education: educData,
    } = data;
    let { skills, languages: language, systems } = data;
    const {
      cvfile,
      previousJobs,
      education,
      skills: cVskills,
      systems: cvSystems,
      yearsOfExperience,
      jobTitle,
    } = cvdata;
    let { languages = [] } = cvdata;
    if (cvfile) {
      docs.push(cvfile._id);
      cvDocs[0] = cvfile;
    }
    const newlyAddedOptions = cVskills
      .map((obj) => {
        skillsOptions = skillsOptions.map((o) => {
          if (o.label.toLowerCase() === obj.toLowerCase()) {
            return { ...o, isSelected: true };
          }
          return o;
        });

        return '';
      })
      .filter((o) => o !== '');
    skills = `${skills},${cVskills.join(',')}`;
    data.skills = skills
      .split(',')
      .filter((skill) => skill.trim().length)
      .join(', ');
    cvSystems
      .map((obj) => {
        systemsOptions = systemsOptions.map((o) => {
          if (o.label.toLowerCase() === obj.toLowerCase()) {
            return { ...o, isSelected: true };
          }
          return o;
        });
        return '';
      })
      .filter((o) => o !== '');
    systems = `${systems},${cvSystems.join(',')}`;
    data.systems = systems
      .split(',')
      .filter((system) => system.trim().length)
      .join(', ');
    skillsOptions = [...skillsOptions, ...newlyAddedOptions];

    const sortedPreviousJobs = previousJobs.length > 0
      ? previousJobs.sort((a, b) => (a.start_date < b.start_date ? 1 : -1)) : [];
    const sortedEducation = education.length > 0
      ? education.sort((a, b) => (a.start_date < b.start_date ? 1 : -1)) : [];
    data.previousJobs = this.handleRemoveDuplicatedData(sortedPreviousJobs, jobData, false);
    data.education = this.handleRemoveDuplicatedData(sortedEducation, educData, true);
    experienceOptions = experienceOptions.map((year) => {
      if (year.value === yearsOfExperience) {
        return { ...year, isSelected: true };
      }
      return { ...year, isSelected: false };
    });
    jobTitleOptions = jobTitleOptions.map((job) => {
      if (job.label === jobTitle) {
        return { ...job, isSelected: true };
      }
      return { ...job, isSelected: false };
    });
    languages = languages.length > 0 ? languages : [];
    languages = languages
      .map((userlanguage) => {
        let lang = userlanguage;
        languagesOptions = languagesOptions.map((option) => {
          if (option.value && option.value.trim().toLowerCase()
            === userlanguage.trim().toLowerCase()) {
            lang = option.label;
            return { ...option, isSelected: true };
          }
          return option;
        });
        return lang;
      })
      .filter((parsedLang) => parsedLang);
    language = `${language}, ${languages.join(', ')}`;
    data.languages = language
      .split(',')
      .filter((skill) => skill.trim().length)
      .join(', ');
    data.jobTitle = jobTitle;
    data.yearsOfExperience = yearsOfExperience;
    data.cvDocs = cvDocs;
    data.docs = docs;
    this.setState(
      {
        data,
        skillsOptions,
        jobTitleOptions,
        experienceOptions,
        languagesOptions,
        systemsOptions,
        loading: false,
      },
      this.validateForm,
    );
  };

  /**
   * Compares the null and empty string and returns value
   * @param {*} current current value
   * @param {*} update updated value
   * @returns boolean value
   */
  handleCheckNull = (current, update) => current === null && update === '';

  /**
   * Collects and merges all education details and filters out the duplications in it.
   * @param {Object} dataFromCV Data gathered from cv
   * @param {Object} currentData non saved education details
   * @param {Object} education saved education details
   */
  handleRemoveDuplicatedData = (dataFromCV, currentData, education) => {
    const data = currentData;
    let newData;
    if (education) {
      newData = dataFromCV
        .map((obj) => {
          if (
            data.find(
              (educ) => (educ.degree === obj.degree
                || this.handleCheckNull(educ.degree, obj.degree))
                && (educ.institution_name === obj.institution_name
                  || this.handleCheckNull(educ.institution_name, obj.institution_name))
                && (educ.start_date === obj.start_date
                  || this.handleCheckNull(educ.start_date, obj.start_date))
                && (educ.end_date === obj.end_date
                  || this.handleCheckNull(educ.end_date, obj.end_date)),
            )
          ) {
            return '';
          }
          return obj;
        })
        .filter((o) => o !== '');
    } else {
      newData = dataFromCV
        .map((obj) => {
          if (
            data.find(
              (job) => (job.title === obj.title || this.handleCheckNull(job.title, obj.title))
                && (job.company_name === obj.company_name
                  || this.handleCheckNull(job.company_name, obj.company_name))
                && (job.start_date === obj.start_date
                  || this.handleCheckNull(job.start_date, obj.start_date))
                && (job.end_date === obj.end_date
                  || this.handleCheckNull(job.end_date, obj.end_date)),
            )
          ) {
            return '';
          }
          return obj;
        })
        .filter((o) => o !== '');
    }
    return [...data, ...newData];
  };

  /**
   * Handle change in documents
   * @param {Object} filedata Uploaded file data
   * @param {String} title Name if the document category
   * @param {Number} index Index value of the uploaded document.
   */
  handleDocumentChange = (filedata, title, index) => {
    const { data } = this.state;
    data.docs.push(filedata.data._id);
    if (title === Constants.docsCategory[0]) {
      data.cvDocs[0] = filedata.data;
    }
    if (title === Constants.docsCategory[1]) {
      data.personalDocs[0] = filedata.data;
    }
    if (title === Constants.docsCategory[2]) {
      data.otherDocs[index] = filedata.data;
    }
    this.setState({ data }, this.validateForm);
  };

  /**
   * Adds more document upload sections
   * @param {Event} e click Event
   */
  handleDocsAddMoreButton = (e) => {
    e.preventDefault();
    const { data, formValid } = this.state;
    data.otherDocs.push({ category: Constants.docsCategory[8] });
    this.setState({ data, formValid }, this.validateForm);
  };

  /**
   * Removes the document
   * @param {Object} filedata Document data
   * @param {String} title document category
   */
  handleRemoveDocument = (filedata, title) => {
    const { data } = this.state;
    data.docs = data.docs.filter((obj) => obj !== filedata._id);
    if (title === Constants.docsCategory[0]) {
      data.cvDocs = [];
    }
    if (title === Constants.docsCategory[1]) {
      data.personalDocs = [];
    }
    if (title === Constants.docsCategory[2]) {
      data.otherDocs = data.otherDocs.filter((obj) => obj._id !== filedata._id);
      if (data.otherDocs.length === 0) {
        data.otherDocs.push({ category: Constants.docsCategory[2] });
      }
    }
    this.setState({ data }, this.validateForm);
  };

  /**
   * Opens file in new tab
   * @param {String} url url of the file to open.
   */
  handleFile = (url) => () => {
    window.open(url);
  };

  /**
   * Removes all the errors oncliking save button
   */
  handleRemoveErrorsOnSave = () => {
    this.setState({ errorFields: [], profileUnfilledErr: false });
  };

  handleDeleteProfilePic= () => {
    const { data } = this.state;
    this.setState({ data: { ...data, profilePic: '' } });
  }

  /**
   * @async
   * @function onProfileImageLoad Uploads profile image
   * @param {Event} e
   */
  onProfileImageLoad = async (e) => {
    const { data } = this.state;
    this.setState({
      profileloading: true,
      imageSizeError: false,
      uploadImageError: false,
    });
    const { inputTypes: { acceptProfileImageTypes } } = Constants;
    const file = e.currentTarget.files && e.currentTarget.files[0];
    if (file && acceptProfileImageTypes.includes(file.type)) {
      if (file.size <= Constants.fileSizeLimit) {
        try {
          const response = await this.profilePageService.uploadProfilePic(file, 'image');
          const { data: imageData = {} } = response.data;
          data.profilePic = imageData;
          this.setState({ data, profileloading: false }, this.validateForm);
        } catch (error) {
          this.setState({ profileloading: false, uploadImageError: true });
        }
      } else {
        this.setState({ imageSizeError: true, profileloading: false });
      }
    } else {
      this.setState({ profileloading: false });
    }
  };

  /**
   * Validates the data on submitting the profile
   */
  handleValidateData = async () => {
    this.validateForm(true);
    const { formValid, errorFields } = this.state;
    let error = false;
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        errorFields.push(obj);
        error = true;
      }
      return error;
    });
    this.setState({
      errorFields, isDisabled: error, profileUnfilledErr: error, showToast: false, loading: false,
    });
  };

  /**
   * Closes toast
   */
     handleToastClose = () => {
       this.setState({ showToast: false });
     };

  /**
   * Validates the form.
   * @param {boolean} validate boolean value
   */
  validateForm = (validate) => {
    const { areChangesSaved, admin } = this.props;
    const { data, formValid } = this.state;
    const {
      phone,
      yearsOfExperience,
      hourlyRate,
      summary,
      workAvailabilityDate,
      desiredCities,
      workAvailability,
      languages,
      skills,
      systems,
      category,
      jobTitle,
      cvDocs,
      firstName,
      lastName,
      email,
    } = data;
    let error = false;
    formValid.phone = !validator.isEmpty(phone) && validator.matches(phone.toString(),
      Constants.phoneNumberRegexPattern);
    if (validator.isEmpty(jobTitle)) {
      formValid.jobTitle = false;
    } else {
      formValid.jobTitle = true;
    }
    if (validator.isEmpty(category)) {
      formValid.category = false;
    } else {
      formValid.category = true;
    }
    if (validator.isEmpty(summary)) {
      formValid.summary = false;
    } else {
      formValid.summary = true;
    }
    if (skills.length < 1) {
      formValid.skills = false;
    } else {
      formValid.skills = true;
    }
    if (systems.length < 1) {
      formValid.systems = false;
    } else {
      formValid.systems = true;
    }
    if (languages.length < 1) {
      formValid.languages = false;
    } else {
      formValid.languages = true;
    }
    if (validator.isEmpty(yearsOfExperience)) {
      formValid.yearsOfExperience = false;
    } else {
      formValid.yearsOfExperience = true;
    }
    if (hourlyRate > 0) {
      formValid.hourlyRate = true;
    } else {
      formValid.hourlyRate = false;
    }
    if (validator.isEmpty(workAvailability)) {
      formValid.workAvailability = false;
    } else {
      formValid.workAvailability = true;
    }
    if (desiredCities.length < 1) {
      formValid.desiredCities = false;
    } else {
      formValid.desiredCities = true;
    }
    if (workAvailabilityDate.length > 0
      && workAvailabilityDate[0].from && workAvailabilityDate[0].to) {
      const dateerror = workAvailabilityDate.filter((obj) => obj && obj.error === true);
      if (dateerror.length > 0) {
        formValid.availableDate = false;
      } else {
        formValid.availableDate = true;
      }
    } else {
      formValid.availableDate = false;
    }
    if (cvDocs.length > 0) {
      formValid.cv = true;
    } else {
      formValid.cv = false;
    }
    if (!validator.isEmpty(firstName)) {
      formValid.firstName = true;
    } else {
      formValid.firstName = false;
    }
    if (!validator.isEmpty(lastName)) {
      formValid.lastName = true;
    } else {
      formValid.lastName = false;
    }
    if (validator.isEmail(email) || admin) {
      formValid.email = true;
    } else {
      formValid.email = false;
    }
    Object.keys(formValid).map((obj) => {
      if (formValid[obj] === false) {
        error = true;
      }
      return error;
    });
    if (validate) {
      this.setState({ isDisabled: error });
    } else {
      this.setState({ isDisabled: error }, areChangesSaved());
    }
    return { error, formValid };
  };

  /**
 * Submits the profile details
 * @param {String} userStatus status of the user
 */
  handleSubmit = (userStatus) => () => {
    this.setState({
      loading: true,
      errorFields: [],
      profileUnfilledErr: false,
      imageSizeError: false,
      uploadImageError: false,
    });
    const { onSubmit, admin } = this.props;
    const { data, languagesOptions, cityOptions } = this.state;
    let { languages, desiredCities } = data;
    if (typeof languages !== 'object') {
      languages = languages.length ? languages.split(',').filter((lang) => lang.trim().length).join(', ') : [];
    }
    const languageValues = [];
    languagesOptions.map((lang) => {
      if (languages.includes(lang.label)) {
        languageValues.push(lang.value);
      }
      return lang;
    });
    if (typeof desiredCities !== 'object') {
      desiredCities = desiredCities.length ? desiredCities.split(',').filter((city) => city.trim().length).join(', ') : [];
    }
    const cityValues = [];
    cityOptions.map((city) => {
      if (desiredCities.includes(city.label)) {
        cityValues.push(city.value);
      }
      return city;
    });
    if (admin) {
      data.review = (data.status === Constants.status.COMPLETED
        || userStatus === Constants.status.COMPLETED);
      data.status = (userStatus === Constants.status.COMPLETED ? userStatus : data.status) || '';
    } else {
      data.status = userStatus;
    }
    data.languages = languageValues || [];
    data.desiredCities = cityValues || [];
    data.profilePic = (data.profilePic && (data.profilePic._id || data.profilePic.id)) || '';
    data.skills = data.skills.length ? data.skills : '';
    data.systems = data.systems.length ? data.systems : '';
    onSubmit(data, userStatus);
  }

  /**
   *
   * @function showDate set the year to the label
   * @param {string} education education object.
   * @returns string
   *
   */
  showDate = (eduaction) => {
    if (eduaction.start_date && eduaction.end_date) {
      return `${eduaction.start_date} - ${eduaction.end_date}`;
    }
    if (eduaction.start_date) {
      return eduaction.start_date;
    }
    if (eduaction.end_date) {
      return eduaction.end_date;
    }
    return null;
  }

  handleSendFeedbacks = async () => {
    this.setState({ loading: true });
    const { feedbacks } = this.state;
    try {
      await this.feedbackService.requestFeedback(feedbacks);
      this.setState({ loading: false, feedbacks: [], successModel: true },
        () => this.successModelElement.click());
    } catch (e) {
      const toastData = {
        type: Constants.toast.ERROR,
        delay: Constants.toast.LONG_DELAY,
        message: Constants.language.toast_try_after_time,
      };
      this.setState({
        loading: false, feedbacks: [], showToast: true, toastData,
      });
    }
  }

  handleOnChangeInFeedbackForm = (index) => (e) => {
    const { feedbacks: prevFeedbacks = [] } = this.state;
    const feedbacks = [...prevFeedbacks];
    const { name, value } = e.target;
    feedbacks[index] = { ...feedbacks[index], [name]: value };
    this.setState({ feedbacks });
  }

  handleAddFeedback = () => {
    const { feedbacks: prevFeedbacks = [] } = this.state;
    const feedbacks = [...prevFeedbacks, { email: '', text: '' }];
    this.setState({ feedbacks });
  }

  handleCloseSuccessModal = () => {
    this.setState({ successModel: false });
  }

  render() {
    const { admin = false, cvStatus } = this.props;
    const {
      profileloading,
      data,
      formValid,
      imageSizeError,
      uploadImageError,
      isDisabled,
      errorFields,
      modalData,
      showModal,
      cityOptions,
      languagesOptions,
      experienceOptions,
      hourlyRateOptions,
      systemsOptions,
      skillsOptions,
      categoryOptions,
      jobTitleOptions,
      originalSkillOptions,
      originalSystemsOptions,
      originalLangOptions,
      isSaveDisabled,
      profileUnfilledErr,
      loading,
      feedbacks = [],
      showToast,
      toastData,
      successModel,
      gradesData,
      assignmentOptions,
    } = this.state;
    const {
      firstName = '',
      lastName = '',
      phone = '',
      profilePic = '',
      jobTitle = '',
      category = '',
      summary = '',
      languages = '',
      yearsOfExperience = '',
      hourlyRate = '',
      previousJobs = [],
      education = [],
      desiredCities = '',
      workAvailabilityDate = '',
      characterCount = 0,
      cvDocs = [],
      personalDocs = [],
      otherDocs = [{ category: 'OtherDocs' }],
      workAvailability = '',
      review = false,
      skills = '',
      systems = '',
      grade = '',
      onAssignmentVia = '',
      notes = '',
      formerNovareConsultant = '',
      negotiatedFee = '',
    } = data;
    const { inputFields } = Constants;

    let isAddMoreDisabled = false;
    otherDocs.forEach((doc) => {
      if (!doc._id) {
        isAddMoreDisabled = true;
      }
    });

    let disableAddMoreFeedbacks = false;
    feedbacks.forEach((obj) => {
      if (!obj.email || !obj.text) {
        disableAddMoreFeedbacks = true;
      }
    });
    return (
      <>
        <ContentWrapper>
          {showToast && (
            <Toast
              onToastClose={this.handleToastClose}
              showToast={showToast}
              type={toastData.type}
              message={toastData.message}
              delay={toastData.delay}
            />
          )}
          <UpperPart>
            {!admin && (
              <Heading>
                <FormattedMessage id="common_my_profile" defaultMessage="My profile" />
              </Heading>
            )}

            <Row className="mt-2">
              <div className="col-l-6 col-xl-6 p-0">
                <FlexContainer bgColor="none" bgImage="none">
                  <ProfileImage>
                    {profileloading || loading ? (
                      <Loader height="100%" width="200px" />
                    ) : (
                      <>
                        {profilePic && profilePic.url ? (
                          <>
                            <div
                              className="image-crop-no-image"
                              style={{
                                backgroundImage: `url("${profilePic.url}")`,
                                backgroundSize: 'cover !important',
                                width: '200px',
                              }}
                            />
                          </>
                        ) : (
                          <div
                            className="image-crop-no-image"
                            style={{
                              backgroundImage: `url("${DefaultProfile}")`,
                              backgroundSize: 'cover !important',
                              width: '200px',
                            }}
                          />
                        )}
                      </>
                    )}
                  </ProfileImage>
                  {!loading && (
                    <UploadWrapper>
                      <UploadButton className="mb-1 mx-auto d-flex justify-content-center">
                        <label className="m-0">
                          <OpenModal role="button">
                            <FormattedMessage id="Profile_upload_image" defaultMessage="Upload image" />
                          </OpenModal>
                          <DragAndDrop
                            accept={Constants.inputTypes.acceptProfileImageTypes}
                            onClick={this.openFileDialog}
                            className="FileInput"
                            type="file"
                            id="file-browser-input"
                            name="file-browser-input"
                            ref={(input) => {
                              this.fileInput = input;
                            }}
                            onChange={this.onProfileImageLoad}
                          />
                        </label>
                      </UploadButton>
                      <UploadCondition className="d-flex justify-content-center">
                        <FormattedMessage id="profile_upload_image_details" defaultMessage="JPG, PNG max 800KB" />
                      </UploadCondition>
                      <Anchor
                        className="d-flex justify-content-center"
                        role="button"
                        onClick={this.handleDeleteProfilePic}
                      >
                        <u className="mb-1">
                          <FormattedMessage id="common_delete" defaultMessage="Delete" />
                        </u>
                      </Anchor>
                      {(imageSizeError || uploadImageError) && (
                        <TextDiv className="text-center">
                          <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                          {imageSizeError && (
                            <span>
                              <FormattedMessage
                                id="common_upload_message_size"
                                defaultMessage="Please check your image size"
                              />
                            </span>
                          )}
                          {uploadImageError && (
                            <span>
                              <FormattedMessage
                                id="common_upload_message_error"
                                defaultMessage="Unable to upload your image try after some time"
                              />
                            </span>
                          )}
                        </TextDiv>
                      )}
                    </UploadWrapper>
                  )}
                </FlexContainer>
              </div>
            </Row>
          </UpperPart>
          <DetailsWrapper>
            <Card>
              <Row>
                <div className="col-l-6 col-xl-6 p-0 mt-2 pl-2">
                  {loading && [...Array(13)].map(() => (
                    <div className="mr-5">
                      <Loader width="200px" marginTop="30px" />
                      <Loader height="40px" width="350px" marginTop="18px" />
                    </div>
                  ))}
                  {!loading
                    && (
                      <FormLeft>
                        <ProfileSubHeading className="m-0">
                          <FormattedMessage id="common_details" defaultMessage="Details" />
                        </ProfileSubHeading>

                        <Row>
                          <div className="col-l-6 col-xl-6 p-0 mt-4 ">
                            <FormFieldWrapperLeft>
                              <SubHeader>
                                <FormattedMessage id="common_first_name" defaultMessage="First name" />
                                *
                              </SubHeader>
                              <TextBox
                                type="Text"
                                name={inputFields.firstName}
                                value={firstName}
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeypress}
                                existError={errorFields.includes(inputFields.firstName)
                                  && !formValid.firstName}
                                existErrorMessage={
                                  <FormattedMessage id="error_first_name" defaultMessage="Enter first name" />
                                }
                              />
                            </FormFieldWrapperLeft>
                          </div>
                          <div className="col-l-6 col-xl-6 p-0 mt-4 ">
                            <FormFieldWrapperRight>
                              <SubHeader>
                                <FormattedMessage id="common_last_name" defaultMessage="Last name" />
                                *
                              </SubHeader>
                              <TextBox
                                type="Text"
                                name={inputFields.lastName}
                                value={lastName}
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeypress}
                                existError={errorFields.includes(inputFields.lastName)
                                  && !formValid.lastName}
                                existErrorMessage={
                                  <FormattedMessage id="error_last_name_message" defaultMessage="Enter last name" />
                                }
                              />
                            </FormFieldWrapperRight>
                          </div>
                        </Row>
                        <Wrapper>
                          <SubHeader>
                            <FormattedMessage id="common_phone" defaultMessage="Phone" />
                            *
                          </SubHeader>
                          <TextBox
                            type="Text"
                            name={inputFields.phone}
                            value={phone}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={
                              <FormattedMessage id="error_phone_message" defaultMessage="Enter a valid phone number" />
                            }
                            existErrorMessage={(
                              <FormattedMessage
                                id="error_phone_number_exists_message"
                                defaultMessage="Enter a valid phone number"
                              />
                            )}
                            existError={errorFields.includes(inputFields.phone) && !formValid.phone}
                            disabletickMark={!formValid.phone}
                          />
                        </Wrapper>
                        {admin
                          && (
                            <Wrapper>
                              <SubHeader>
                                <FormattedMessage id="common_email" defaultMessage="E-mail" />
                              </SubHeader>
                              <TextBox
                                type="email"
                                name="email"
                                value={data.email}
                                onChange={this.handleChange}
                                existError={formValid.email_isValid}
                                existErrorMessage={(
                                  <FormattedMessage
                                    id="error_email_taken_message_1"
                                    defaultMessage="This e-mail address is already registered"
                                  />
                                )}
                                errorMessage={
                                  <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                                }
                                rule={{
                                  method: validator.isEmail,
                                  validWhen: true,
                                }}
                              />
                            </Wrapper>
                          )}
                        <Wrapper>
                          <SubHeader>
                            <FormattedMessage id="common_job_title" defaultMessage="Job title" />
                            *
                          </SubHeader>
                          <Select
                            name={inputFields.jobTitle}
                            placeholder={Constants.language.job_title_placeholer}
                            search
                            value={jobTitle}
                            onChange={this.handleSelectChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={(
                              <FormattedMessage
                                id="error_job_title_message"
                                defaultMessage="Please select your job title"
                              />
                            )}
                            existErrorMessage={(
                              <FormattedMessage
                                id="error_job_title_message"
                                defaultMessage="Please select your job title"
                              />
                            )}
                            existError={errorFields.includes(inputFields.jobTitle)
                              && !formValid.jobTitle}
                            options={jobTitleOptions}
                            searchPlaceholder={Constants.language.select_search_or_add}
                            noEmptyError
                            canAddNewOption
                          />
                        </Wrapper>
                        <>
                          <Wrapper>
                            <SubHeader>
                              <FormattedMessage id="common_category" defaultMessage="Category" />
                              *
                            </SubHeader>
                            <Select
                              name={inputFields.category}
                              search
                              options={categoryOptions}
                              value={category}
                              searchPlaceholder={Constants.language.common_search}
                              onChange={this.handleSelectChange}
                              onKeyPress={this.handleKeypress}
                              errorMessage={
                                <FormattedMessage id="error_category_message" defaultMessage="Select your category" />
                              }
                              existErrorMessage={
                                <FormattedMessage id="error_category_message" defaultMessage="Select your category" />
                              }
                              existError={errorFields.includes(inputFields.category)
                                && !formValid.category}
                              noEmptyError
                              canAddNewOption={false}
                            />
                          </Wrapper>
                        </>
                        <>
                          {' '}
                          <Wrapper>
                            <div className="row m-0">
                              <SubHeader className="col-auto">
                                <FormattedMessage id="common_summary" defaultMessage="Summary" />
                                *
                              </SubHeader>
                              <CharactersLeft className="col text-end">
                                {characterCount}
                                /
                                {Constants.profileSummaryLength}
                                {' '}
                                <FormattedMessage id="profile_summary_characters_left" defaultMessage="characters left" />
                              </CharactersLeft>
                            </div>
                            <TextArea
                              type="summary"
                              name={inputFields.summary}
                              maxLength={Constants.profileSummaryLength}
                              value={summary}
                              onChange={this.handleChange}
                              onKeyPress={this.handleKeypress}
                              placeholder={Constants.language.common_profile_summary_placeholder.replace('{break}', '\n\n')}
                              errorMessage={(
                                <FormattedMessage
                                  id="error_summary_message"
                                  defaultMessage="Please write a short summary"
                                />
                              )}
                              existErrorMessage={(
                                <FormattedMessage
                                  id="error_summary_message"
                                  defaultMessage="Please write a short summary"
                                />
                              )}
                              existError={errorFields.includes(inputFields.summary)
                                && !formValid.summary}
                            />
                          </Wrapper>
                        </>
                        <Wrapper>
                          <SubHeader>
                            <FormattedMessage id="common_professional_level" defaultMessage="Professional level" />
                          </SubHeader>
                          {yearsOfExperience !== '' && (
                            <>
                              {yearsOfExperience === '0-3' ? (
                                <JuniorLevelTag>
                                  <FormattedMessage id="common_junior" defaultMessage="Junior" />
                                </JuniorLevelTag>
                              ) : yearsOfExperience === '4-10' ? (
                                <ExperienceLevelTag>
                                  <FormattedMessage id="common_experienced" defaultMessage="Experienced" />
                                </ExperienceLevelTag>
                              ) : (
                                <MidLevelTag>
                                  <FormattedMessage id="common_senior" defaultMessage="Senior" />
                                </MidLevelTag>
                              )}
                            </>
                          )}
                        </Wrapper>
                        <Row>
                          <div className="col-l-6 col-xl-6 mt-4 p-0 pb-3">
                            <FormFieldWrapperLeft>
                              <SubHeader>
                                <FormattedMessage id="common_hourly_rate" defaultMessage="Hourly rate, SEK" />
                                *
                              </SubHeader>
                              <Select
                                name={inputFields.hourlyRate}
                                search={false}
                                value={hourlyRate}
                                options={hourlyRateOptions}
                                onChange={this.handleSelectChange}
                                onKeyPress={this.handleKeypress}
                                existErrorMessage={(
                                  <FormattedMessage
                                    id="error_hourly_rate_message"
                                    defaultMessage="Select your hourly rate"
                                  />
                                )}
                                existError={errorFields.includes(inputFields.hourlyRate)
                                  && !formValid.hourlyRate}
                                errorMessageClass="position-absolute"
                                errorMessage={(
                                  <FormattedMessage
                                    id="error_hourly_rate_message"
                                    defaultMessage="Select your hourly rate"
                                  />
                                )}
                                noEmptyError
                                disabled={admin ? !!negotiatedFee : false}
                              />
                            </FormFieldWrapperLeft>
                          </div>
                          <div className="col-l-6 col-xl-6 p-0 mt-4 pb-3">
                            <HourlyRateSection>
                              <SubHeader>
                                <FormattedMessage id="common_years_of_experience" defaultMessage="Years of experience" />
                                *
                              </SubHeader>
                              <Select
                                name={inputFields.yearsOfExperience}
                                search
                                options={experienceOptions}
                                value={yearsOfExperience}
                                onChange={this.handleSelectChange}
                                onKeyPress={this.handleKeypress}
                                existErrorMessage={(
                                  <FormattedMessage
                                    id="common_years_of_experience"
                                    defaultMessage="Enter years of experience"
                                  />
                                )}
                                existError={
                                  errorFields.includes(inputFields.yearsOfExperience)
                                  && !formValid.yearsOfExperience
                                }
                                errorMessage={(
                                  <FormattedMessage
                                    id="common_years_of_experience"
                                    defaultMessage="Enter years of experience"
                                  />
                                )}
                                errorMessageClass="position-absolute"
                                noEmptyError
                                canAddNewOption
                              />
                            </HourlyRateSection>
                          </div>
                        </Row>
                        {admin && (
                          <Wrapper className="mt-0">
                            <CheckBox
                              isSelected={negotiatedFee}
                              label={Constants.language.negotiated_fee}
                              onClick={this.handleNegotiatedFee}
                              bgcolor={Colors.Primary}
                            />
                          </Wrapper>
                        )}
                        <Wrapper>
                          <ProfileSubHeading className="mb-2">
                            <Span>
                              {' '}
                              <FormattedMessage id="common_upload_cv" defaultMessage="Upload CV" />
                              *

                            </Span>
                            {admin && cvStatus && (
                              <Span className="ml-3">
                                <FilterButton
                                  className="w-auto px-3 py-1 mb-0 h6"
                                  approve={cvStatus === Constants.status.CV_APPROVED}
                                  open={cvStatus !== Constants.status.CV_APPROVED}
                                >
                                  {Constants[cvStatus]}
                                </FilterButton>
                              </Span>
                            )}
                          </ProfileSubHeading>
                          <UploadCondition>
                            <FormattedMessage
                              id="common_upload_cv_info"
                              defaultMessage="Upload your Cv so we can lorem ipsum. Maximum 800 kb"
                            />
                          </UploadCondition>
                          <UploadCondition>
                            <FormattedMessage
                              id="common_download_cv_info"
                              defaultMessage="Download your CV from linkeding? "
                            />
                            {' '}
                            <WhiteButton onClick={this.handleExportCVTutorial}>
                              <FormattedMessage id="profile_watch_tutorial" defaultMessage="Watch tutorial" />
                            </WhiteButton>
                          </UploadCondition>
                        </Wrapper>

                        {errorFields.includes(inputFields.cv) && !formValid.cv && (
                          <TextDiv className="d-flex align-items-center mb-1">
                            <Image
                              src={Constants.icons.ErrorIcon}
                              alt="error"
                              width="12px"
                              height="12px"
                              className="mr-2"
                            />
                            <Span>
                              {' '}
                              <FormattedMessage
                                id="error_upload_documents_message"
                                defaultMessage="Please attach your documents"
                              />
                              {' '}
                            </Span>
                          </TextDiv>
                        )}
                        <UploadDocument className="col p-2">
                          <Text className="text-truncate">
                            <WhiteButton
                              onClick={
                                cvDocs[0] ? this.handleFile(cvDocs[0].url) : this.handleUploadCV
                              }
                              role="button"
                            >
                              {cvDocs[0] ? (
                                cvDocs[0].name || cvDocs[0].file_name
                              ) : (
                                <FormattedMessage id="common_upload_cv" defaultMessage="Upload CV" />
                              )}
                            </WhiteButton>
                          </Text>
                        </UploadDocument>
                        {cvDocs[0] && (
                          <div className="col text-end p-0 pt-1">
                            <CloseButton
                              onClick={() => this.handleRemoveDocument(cvDocs[0],
                                Constants.docsCategory[0])}
                              role="button"
                            >
                              <FormattedMessage id="common_remove" defaultMessage="Remove" />
                            </CloseButton>
                          </div>
                        )}

                        <Wrapper>
                          <SubHeader head>
                            <FormattedMessage id="common_previos_jobs" defaultMessage="Previous jobs" />
                          </SubHeader>
                          {previousJobs.map((obj, index) => (
                            <>
                              <Border />
                              <Div className="row m-0 py-2">
                                <Div className="col">
                                  <Name>{obj.title}</Name>
                                  <Description>{obj.company_name}</Description>
                                  <Description>
                                    {obj.start_date}
                                    {' '}
                                    -
                                    {obj.end_date}
                                  </Description>
                                </Div>
                                <Div className="col-auto">
                                  <EditButton
                                    onClick={
                                      this.handleModalOpen(inputFields.previousJobs, obj, index)
                                    }
                                    role="button"
                                  >
                                    <FormattedMessage id="common_edit" defaultMessage="Edit" />
                                  </EditButton>
                                  <EditButton
                                    onClick={() => this.handleRemovePreviousJob(index)}
                                    role="button"
                                    className="mt-2"
                                  >
                                    <FormattedMessage id="common_remove" defaultMessage="Remove" />
                                  </EditButton>
                                </Div>
                              </Div>
                            </>
                          ))}
                          {previousJobs && previousJobs.length > 0 && (
                            <Div className="mb-2">
                              <Border />
                            </Div>
                          )}

                          <UploadCondition onClick={this.handleAddData(inputFields.previousJobs)} role="button">
                            <img src={Constants.icons.Plus} alt="icon" className="mr-2" />
                            <OpenModal>
                              <FormattedMessage id="common_add_more" defaultMessage="Add more" />
                            </OpenModal>
                          </UploadCondition>
                        </Wrapper>
                        <Wrapper>
                          <SubHeader head>
                            <FormattedMessage id="common_add_education" defaultMessage="Add education" />
                          </SubHeader>
                          {education.map((obj, index) => (
                            <>
                              <Border />
                              <Div className="row m-0 py-2">
                                <Div className="col">
                                  <Name>{obj.institution_name}</Name>
                                  <Description>{obj.degree}</Description>
                                  <Description>
                                    {this.showDate(obj)}
                                  </Description>
                                </Div>
                                <Div className="col-auto">
                                  <EditButton
                                    onClick={
                                      this.handleModalOpen(inputFields.education, obj, index)
                                    }
                                    role="button"
                                  >
                                    <FormattedMessage id="common_edit" defaultMessage="Edit" />
                                  </EditButton>
                                  <EditButton
                                    onClick={() => this.handleRemoveEducation(index)}
                                    role="button"
                                    className="mt-2"
                                  >
                                    <FormattedMessage id="common_remove" defaultMessage="Remove" />
                                  </EditButton>
                                </Div>
                              </Div>
                            </>
                          ))}
                          {education && education.length > 0 && (
                            <Div className="mb-2">
                              <Border />
                            </Div>
                          )}
                          <UploadCondition onClick={this.handleAddData(inputFields.education)} role="button">
                            <img src={Constants.icons.Plus} alt="icon" className="mr-2" />
                            <OpenModal>
                              <FormattedMessage id="common_add_more" defaultMessage="Add more" />
                            </OpenModal>
                          </UploadCondition>
                        </Wrapper>
                        <div className="mt-4">
                          <SubHeader>
                            <FormattedMessage id="common_systems" defaultMessage="Systems" />
                            *
                          </SubHeader>
                          <MultiSelect
                            showSearchAndAdd
                            name={inputFields.systems}
                            options={systemsOptions}
                            value={systems}
                            onChange={this.handleSelectChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={
                              <FormattedMessage id="error_systems_message" defaultMessage="Select your skills" />
                            }
                            existErrorMessage={
                              <FormattedMessage id="error_systems_message" defaultMessage="Select your skills" />
                            }
                            existError={
                              errorFields.includes(inputFields.systems) && !formValid.systems
                            }
                            noEmptyError
                            placeholder={Constants.language.select_placeholder_choose}
                          />
                        </div>
                        <div className="mt-4">
                          <SubHeader>
                            <FormattedMessage id="common_skills" defaultMessage="Skills" />
                            *
                          </SubHeader>
                          <MultiSelect
                            showSearchAndAdd
                            name={inputFields.skills}
                            options={skillsOptions}
                            value={skills}
                            onChange={this.handleSelectChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={
                              <FormattedMessage id="error_skills_message" defaultMessage="Select your skills" />
                            }
                            existErrorMessage={
                              <FormattedMessage id="error_skills_message" defaultMessage="Select your skills" />
                            }
                            existError={
                              errorFields.includes(inputFields.skills) && !formValid.skills
                            }
                            noEmptyError
                            placeholder={Constants.language.select_placeholder_choose}
                          />
                        </div>
                        <div className="mt-4">
                          <SubHeader>
                            <FormattedMessage id="common_languages_spoken" defaultMessage="Language Spoken" />
                            *
                          </SubHeader>
                          <MultiSelect
                            showSearch
                            name={inputFields.languages}
                            value={languages}
                            options={languagesOptions}
                            onChange={this.handleSelectChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={
                              <FormattedMessage id="error_language_message" defaultMessage="Select  languages" />
                            }
                            existErrorMessage={
                              <FormattedMessage id="error_language_message" defaultMessage="Select  languages" />
                            }
                            existError={errorFields.includes(inputFields.languages)
                              && !formValid.languages}
                            noEmptyError
                            placeholder={Constants.language.select_placeholder_choose}
                          />
                        </div>
                        <Wrapper>
                          <ProfileSubHeading className="mb-2">
                            <FormattedMessage id="common_upload_other_documents" defaultMessage="Upload other documents" />
                          </ProfileSubHeading>
                          <UploadCondition>
                            2 mb
                          </UploadCondition>
                        </Wrapper>

                        <SubHeader head>
                          <FormattedMessage id="common_personal_letter" defaultMessage="Personal letter" />
                        </SubHeader>
                        <Document
                          files={personalDocs[0]}
                          onChange={this.handleDocumentChange}
                          onRemove={this.handleRemoveDocument}
                          title={Constants.docsCategory[1]}
                          allowedTypes={Constants.inputTypes.acceptDocTypes}
                        />
                        <SubHeader head>
                          <FormattedMessage
                            id="profile_other_docs_upload_info"
                            defaultMessage="Other documents: diplomas,licenses, certifications etc"
                          />
                        </SubHeader>
                        <>
                          {otherDocs.map((obj, index) => (
                            <Document
                              files={obj}
                              indexValue={index}
                              onChange={this.handleDocumentChange}
                              onRemove={this.handleRemoveDocument}
                              title={Constants.docsCategory[2]}
                              allowedTypes={Constants.inputTypes.acceptFileTypes}
                            />
                          ))}
                        </>
                        {otherDocs.length < 4 && (
                          <Wrapper>
                            <UploadCondition
                              role="button"
                              onClick={this.handleDocsAddMoreButton}
                              className={isAddMoreDisabled ? 'disableOption' : ''}
                            >
                              <img src={Constants.icons.Plus} alt="icon" className="mr-2" />
                              <OpenModal>
                                <FormattedMessage id="common_add_more" defaultMessage="Add more" />
                              </OpenModal>
                            </UploadCondition>
                          </Wrapper>
                        )}
                      </FormLeft>
                    )}
                </div>
                <div className="col-l-6 col-xl-6 p-0 mt-2 pr-2">
                  {loading && [...Array(5)].map(() => (
                    <Wrapper className="mr-5">
                      <Loader width="200px" marginTop="30px" />
                      <Loader height="40px" width="350px" marginTop="18px" />
                    </Wrapper>
                  ))}
                  {!loading
                    && (
                      <FormRight>
                        <ProfileSubHeading className="mb-4">
                          <FormattedMessage id="common_work_availability" defaultMessage="Work availability" />
                          *
                        </ProfileSubHeading>
                        <>
                          <div className="mb-3 p-0">
                            <RadioButton
                              key={Constants.keys.profileFullTime}
                              label={<FormattedMessage id="common_full_time" defaultMessage="Full-time" />}
                              onClick={this.handleworkAvailability(Constants.fullTime)}
                              isSelected={workAvailability === Constants.fullTime}
                            />
                          </div>
                          <RadioButton
                            key={Constants.keys.profilePartTime}
                            label={<FormattedMessage id="common_part_time" defaultMessage="Part-time" />}
                            onClick={this.handleworkAvailability(Constants.partTime)}
                            isSelected={workAvailability === Constants.partTime}
                          />
                        </>

                        {errorFields.includes(inputFields.workAvailability)
                          && !formValid.workAvailability && (
                            <TextDiv>
                              <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                              <span>
                                <FormattedMessage
                                  id="error_work_availability"
                                  defaultMessage="Choose your work availability"
                                />
                              </span>
                            </TextDiv>
                        )}
                        <Wrapper>
                          <SubHeader>
                            <FormattedMessage id="common_desired_work_location" defaultMessage="Desired work location" />
                            *
                          </SubHeader>
                          <MultiSelect
                            showSearch
                            name={inputFields.desiredCities}
                            options={cityOptions}
                            value={desiredCities}
                            onChange={this.handleSelectChange}
                            onKeyPress={this.handleKeypress}
                            errorMessage={(
                              <FormattedMessage
                                id="error_desired_work_location_message"
                                defaultMessage="Select your desired work location"
                              />
                            )}
                            existErrorMessage={(
                              <FormattedMessage
                                id="error_desired_work_location_message"
                                defaultMessage="Select your desired work location"
                              />
                            )}
                            existError={errorFields.includes(inputFields.desiredCities)
                              && !formValid.desiredCities}
                            noEmptyError
                            placeholder={Constants.language.select_placeholder_choose}
                          />
                        </Wrapper>
                        {workAvailabilityDate.length > 0
                          && workAvailabilityDate.map((obj, index) => (
                            <>
                              <Row>
                                <div className="col-l-6 col-xl-6 mt-4 p-0">
                                  <FormFieldWrapperLeft>
                                    <SubHeader>
                                      <FormattedMessage id="common_available_from" defaultMessage="Available from" />
                                      *
                                    </SubHeader>
                                    <CustomDatePicker
                                      type="date"
                                      name="from"
                                      id={`date${index}`}
                                      value={obj.from}
                                      placeholder="YYYY-MM-DD"
                                      existError={obj.error || false}
                                      existErrorMessage={(
                                        <FormattedMessage
                                          id="error_message_from_date_is_greater"
                                          defaultMessage="From date shouldn't be greater than to date"
                                        />
                                      )}
                                      onChange={this.handleAvailabilityDates(index)}
                                      onKeyPress={this.handleKeypress}
                                      errorMessage={(
                                        <FormattedMessage
                                          id="error_valid_date_message"
                                          defaultMessage="Enter a valid date"
                                        />
                                      )}
                                    />
                                  </FormFieldWrapperLeft>
                                </div>
                                <div className="col-l-6 col-xl-6 p-0 mt-4 ">
                                  <FormFieldWrapperRight>
                                    <SubHeader>
                                      <FormattedMessage id="common_available_to" defaultMessage="Available to" />
                                      *
                                    </SubHeader>
                                    <CustomDatePicker
                                      type="date"
                                      name="to"
                                      id={`date${index}`}
                                      value={obj.to}
                                      placeholder="YYYY-MM-DD"
                                      onChange={this.handleAvailabilityDates(index)}
                                      onKeyPress={this.handleKeypress}
                                      errorMessage={(
                                        <FormattedMessage
                                          id="common_enter_a_valid_date"
                                          defaultMessage="Enter a valid date"
                                        />
                                      )}
                                    />
                                  </FormFieldWrapperRight>
                                </div>
                              </Row>
                              {workAvailabilityDate.length > 1 && (
                                <div className="col text-end p-0 pt-1">
                                  <CloseButton onClick={this.handleRemoveWorkAvailability(index)} role="button">
                                    <FormattedMessage id="common_remove" defaultMessage="Remove" />
                                  </CloseButton>
                                </div>
                              )}
                            </>
                          ))}
                        {workAvailabilityDate && workAvailabilityDate.length < 6 && (
                          <Row className="justify-content-end mt-2">
                            <UploadCondition role="button" onClick={this.handleAddMoreDates}>
                              <img src={Constants.icons.Plus} alt="icon" className="mr-2" />
                              <OpenModal>
                                <FormattedMessage id="common_add_more" defaultMessage="Add more" />
                              </OpenModal>
                            </UploadCondition>
                          </Row>
                        )}
                        {errorFields.includes(inputFields.availableDate)
                          && !formValid.availableDate && (
                            <TextDiv>
                              <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                              <span>
                                <FormattedMessage
                                  id="error_availability_message"
                                  defaultMessage="Enter your availability dates"
                                />
                              </span>
                            </TextDiv>
                        )}
                        {!admin && (
                        <Div className="my-2 font-italic">
                          <FormattedMessage
                            id="edit_profile_page_description"
                            defaultMessage="GDBR_description"
                          />
                        </Div>
                        )}
                        {admin && (
                          <>
                            <ProfileSubHeading className="mb-4">
                              <FormattedMessage id="label_additional" defaultMessage="Additional" />
                            </ProfileSubHeading>
                            <Wrapper>
                              <SubHeader>
                                <FormattedMessage id="grade" defaultMessage="Grade" />
                              </SubHeader>
                              <Select
                                name={inputFields.grade}
                                placeholder={Constants.language.choose_grade}
                                search
                                value={grade}
                                onChange={this.handleSelectChange}
                                onKeyPress={this.handleKeypress}
                                options={gradesData}
                                searchPlaceholder={Constants.language.common_search}
                                noEmptyError
                              />
                            </Wrapper>
                            <Wrapper>
                              <SubHeader>
                                <FormattedMessage id="label_on_assignment_via" defaultMessage="On assignment via" />
                              </SubHeader>
                              <Select
                                name={inputFields.onAssignmentVia}
                                placeholder={Constants.language.select_placeholder_choose}
                                search
                                value={onAssignmentVia}
                                onChange={this.handleSelectChange}
                                onKeyPress={this.handleKeypress}
                                options={assignmentOptions}
                                searchPlaceholder={Constants.language.common_search}
                                noEmptyError
                              />
                            </Wrapper>
                            <Wrapper>
                              <SubHeader>
                                <FormattedMessage id="label_former_novare_consultant" defaultMessage="Former Novare consultant" />
                              </SubHeader>
                              <div className="mb-3 p-0">
                                <RadioButton
                                  key={Constants.keys.formerNovareYes}
                                  label={<FormattedMessage id="common_yes" defaultMessage="Yes" />}
                                  onClick={this.handleFormerNovreConsultant(true)}
                                  isSelected={formerNovareConsultant === true}
                                />
                              </div>
                              <RadioButton
                                key={Constants.keys.formerNovareNo}
                                label={<FormattedMessage id="common_no" defaultMessage="No" />}
                                onClick={this.handleFormerNovreConsultant(false)}
                                isSelected={formerNovareConsultant === false}
                              />
                            </Wrapper>
                            <Wrapper>
                              <SubHeader>
                                <FormattedMessage id="label_notes" defaultMessage="Notes" />
                              </SubHeader>
                              <TextArea
                                type="summary"
                                name={inputFields.notes}
                                value={notes}
                                onChange={this.handleChange}
                                onKeyPress={this.handleKeypress}
                                placeholder={Constants.language.placeholder_notes}
                              />
                            </Wrapper>
                          </>
                        )}
                        {admin ? (
                          <Row>
                            {data.status !== Constants.status.APPROVED && !review && (
                              <div className="col-l-6 col-xl-6 mt-4 p-0">
                                <FormFieldWrapperLeft>
                                  {isDisabled ? (
                                    <span className="opacity-5">
                                      <Button
                                        type="update"
                                        name={(
                                          <FormattedMessage
                                            id="common_finish_profile"
                                            defaultMessage="Finish profile"
                                          />
                                        )}
                                        onClick={this.handleValidateData}
                                      />
                                    </span>
                                  ) : (
                                    <Button
                                      type="update"
                                      name={
                                        <FormattedMessage id="common_finish_profile" defaultMessage="Finish profile" />
                                      }
                                      disabled={isDisabled}
                                      onClick={this.handleSubmit(Constants.status.COMPLETED)}
                                    />
                                  )}

                                </FormFieldWrapperLeft>
                              </div>
                            )}
                            <div className="col-l-6 col-xl-6 mt-4 p-0">
                              <FormFieldWrapperRight className="h-100">
                                <Button
                                  type="cancel"
                                  className={`${!!isCvVerificationUser() && 'disableOption'} h-100`}
                                  name={data.status !== Constants.status.APPROVED && !review ? <FormattedMessage id="common_save_as_draft" defaultMessage="Save as draft" /> : <FormattedMessage id="common_save" defaultMessage="Save" />}
                                  disabled={isSaveDisabled}
                                  role="button"
                                  onClick={this.handleSubmit(Constants.status.DRAFT)}
                                />
                              </FormFieldWrapperRight>
                            </div>
                          </Row>
                        )
                          : (
                            <Row>
                              <div className="col-l-6 col-xl-6 mt-4 p-0">
                                <FormFieldWrapperLeft>
                                  {isDisabled ? (
                                    <span className="opacity-5">
                                      <Button
                                        type="update"
                                        name={<FormattedMessage id="common_finish_profile" defaultMessage="Finish profile" />}
                                        onClick={this.handleValidateData}
                                      />
                                    </span>
                                  ) : (
                                    <Button
                                      type="update"
                                      name={<FormattedMessage id="common_finish_profile" defaultMessage="Finish profile" />}
                                      disabled={isDisabled}
                                      onClick={this.handleSubmit(Constants.status.COMPLETED)}
                                    />
                                  )}
                                </FormFieldWrapperLeft>
                              </div>
                              {!review && (
                                <div className="col-l-6 col-xl-6 p-0 mt-4 ">
                                  <FormFieldWrapperRight className="h-100">
                                    <Button
                                      type="cancel"
                                      className="h-100"
                                      name={<FormattedMessage id="common_save_as_draft" defaultMessage="Save as draft" />}
                                      disabled={isSaveDisabled}
                                      role="button"
                                      onClick={this.handleSubmit(Constants.status.DRAFT)}
                                    />
                                  </FormFieldWrapperRight>
                                </div>
                              )}
                            </Row>
                          )}
                        {profileUnfilledErr && isDisabled && (
                          <TextDiv>
                            <img src={Constants.icons.ErrorIcon} alt="icon" className="mr-1" width="12px" height="12px" />
                            <span>
                              <FormattedMessage
                                id="profile_unfilled_error"
                                defaultMessage="Some fields are left unfilled"
                              />
                            </span>
                          </TextDiv>
                        )}
                        {!admin && (
                          <StyledCard message className="mt-5">
                            <Label className="disableOption">
                              <FormattedMessage id="common_optional" defaultMessage="Optional" />
                            </Label>
                            <OrderServicesHeader className="text-left">
                              <FormattedMessage id="header_feedback" defaultMessage="Tip! Ask for feedback" />
                            </OrderServicesHeader>
                            <ButtonName cancel>
                              <FormattedMessage id="message_send_feedback" defaultMessage="To increase your profile strength, you can ask for feedback from previous clients. This can also be done at a later stage under ”edit your profile”." />
                            </ButtonName>
                            {!!feedbacks.length
                              && (
                                <>
                                  {feedbacks.map((feedback, i) => (
                                    <div className="mt-3">
                                      <TextBox
                                        type="email"
                                        name="email"
                                        value={feedback.email}
                                        label={<FormattedMessage id="common_email" defaultMessage="E-mail" />}
                                        onChange={this.handleOnChangeInFeedbackForm(i)}
                                        errorMessage={
                                          <FormattedMessage id="error_email_message" defaultMessage="Enter a valid email" />
                                }
                                        rule={{
                                          method: validator.isEmail,
                                          validWhen: true,
                                        }}
                                      />
                                      <Row className="mt-3">
                                        <TextArea
                                          type="text"
                                          name="text"
                                          value={feedback.text}
                                          label={<FormattedMessage id="text_message" defaultMessage="Message" />}
                                          onChange={this.handleOnChangeInFeedbackForm(i)}
                                          errorMessage={<FormattedMessage id="error_feedback_message" defaultMessage="Share your feedback" />}
                                          rule={{
                                            method: validator.isEmpty,
                                            validWhen: false,
                                          }}
                                          maxLength={2000}
                                          mandatory={`${feedback.text.length}/2000 characters left`}
                                        />
                                      </Row>
                                    </div>
                                  ))}
                                  <div className="d-flex align-self-center justify-content-between">
                                    <Button
                                      type="update"
                                      className="w-auto mt-3"
                                      name={
                                        <FormattedMessage id="common_send" defaultMessage="Send" />
                                }
                                      onClick={this.handleSendFeedbacks}
                                    />
                                    <Anchor
                                      className={`${disableAddMoreFeedbacks && 'disableOption'} mb-0 align-self-center`}
                                      onClick={this.handleAddFeedback}
                                      role="button"
                                    >
                                      <Image src={Constants.icons.Plus} alt="icon" className="mr-1" />
                                      <u>
                                        <FormattedMessage id="common_add_more" defaultMessage="Add more" />
                                      </u>
                                    </Anchor>
                                  </div>
                                </>
                              )}
                              {!feedbacks.length && (
                                <Button
                                  type="update"
                                  className="w-auto mt-3"
                                  name={
                                    <FormattedMessage id="button_send_feedback" defaultMessage="Send feedback" />
                                }
                                  onClick={this.handleAddFeedback}
                                />
                              )}
                          </StyledCard>
                        )}
                      </FormRight>
                    )}
                </div>
              </Row>
              <a
                href
                data-backdrop="static"
                data-keyboard="false"
                data-toggle="modal"
                data-target="#UploadCvModal"
                ref={(openCvModal) => {
                  this.openCvElement = openCvModal;
                }}
              />
              {showModal === inputFields.cv && (
                <UploadCvModal
                  uploadCV
                  ModalClose={this.handleModalClose}
                  skillsOptions={originalSkillOptions}
                  systemsOptions={originalSystemsOptions}
                  languagesOptions={originalLangOptions}
                  handleUpdateCV={this.handleUpdateCV}
                  jobTitle={jobTitle}
                  yearsOfExperience={yearsOfExperience}
                />
              )}
            </Card>
          </DetailsWrapper>
        </ContentWrapper>
        <a
          href
          data-backdrop="static"
          data-keyboard="false"
          data-toggle="modal"
          data-target="#profilePageModal"
          ref={(openModal) => {
            this.openElement = openModal;
          }}
        />
        {showModal === inputFields.education && (
          <Modal
            education
            editData={modalData}
            collectData={this.handleCollectEducationData}
            ismodalopen
            ModalClose={this.handleModalClose}
          />
        )}
        {showModal === inputFields.previousJobs && (
          <Modal
            editData={modalData}
            collectData={this.handleCollectPreviousJobsData}
            education={false}
            ismodalopen
            ModalClose={this.handleModalClose}
          />
        )}
        <a
          href
          data-backdrop="static"
          data-keyboard="false"
          data-toggle="modal"
          data-target="#successModal"
          ref={(successModal) => {
            this.successModelElement = successModal;
          }}
        />
        {successModel && <SuccessModal onClick={this.handleCloseSuccessModal} />}
      </>
    );
  }
}
export default ProfileForm;
