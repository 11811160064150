import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Constants from '../shared/constants';
import AuthService from '../services/AuthService';
import SideMenu from './Sidemenu/index';
import Header from './AppHeader/index';
import Footer from './AppFooter/Footer';

function Container(props) {
  const authService = new AuthService();
  const { children } = props;
  const routes = {
    home: {
      url: '/',
      title: 'Home',
      nav: [],
    },
    noPage: {
      url: '/welcome',
      title: 'Welcome',
      nav: [],
    },
    logIn: {
      url: '/login',
      title: 'LogIn',
      nav: [],
    },
    logOut: {
      url: '/logout',
      title: 'LogOut',
      nav: [],
    },
    signUp: {
      url: '/signup',
      title: 'SignUp',
      nav: [],
    },
    customerSignUp: {
      url: '/customer-signup',
      title: 'Customer SignUp',
      nav: [],
    },
    customerSignUpWithOutBankId: {
      url: '/customer-signup/without-bankId',
      title: 'Customer SignUp',
      nav: [],
    },
    giggerSignUp: {
      url: '/gigger-signup',
      title: 'Gigger SignUp',
      nav: [],
    },
    forgotPassword: {
      url: '/forgot-password',
      title: 'Forgot Password',
      nav: [],
    },
    resetPassword: {
      url: '/reset-password',
      title: 'Reset Password',
      nav: [],
    },
    signUpconfirmationPage: {
      url: '/sigup-confirmationPage',
      title: 'SignUp Confirmation Page',
    },
    giggerMyAccount: {
      url: '/gigger-myaccount',
      title: 'My Account',
      nav: [],
    },
    customerMyAccount: {
      url: '/customer-myaccount',
      title: 'My Account',
      nav: [],
    },
    adminMyAccount: {
      url: '/admin/my-account',
      title: 'My Account',
      nav: [],
    },
    giggerUserDetails: {
      url: '/gigger-userdetails',
      title: 'User Details',
      nav: [],
    },
    customerUserDetails: {
      url: '/customer-userdetails',
      title: 'User Details',
      nav: [],
    },
    adminUserDetails: {
      url: '/admin/user-details',
      title: 'User Details',
      nav: [],
    },
    giggerCompanyDetails: {
      url: '/gigger-companydetails',
      title: 'Company Details',
      nav: [],
    },
    customerCompanyDetails: {
      url: '/customer-companydetails',
      title: 'Company Details',
      nav: [],
    },
    giggerDetails: { url: '/details/:username-:id', title: 'Gigger Details', nav: [] },
    customerDetailView: {
      url: '/customer-detailview',
      title: 'Detail View',
      nav: [],
    },
    customerOrders: {
      url: '/customer-orders',
      title: 'Orders',
      nav: [],
    },
    giggerMyGiggs: {
      url: '/gigger-mygiggs',
      title: 'My Giggs',
      nav: [],
    },
    myProfile: {
      url: '/profile/:firstname-:lastname-:id',
      title: 'Profile',
      nav: [],
    },
    customerMyGiggers: {
      url: '/customer-mygiggers',
      title: 'My Giggers',
      nav: [],
    },
    manageUsers: {
      url: '/customer-manageusers',
      title: 'Manage Users',
      nav: [],
    },
    hiredGiggerFeedback: {
      url: '/customer-feedback',
      title: 'Hired Gigger Feedback',
      nav: [],
    },
    hiredDates: {
      url: '/customer-hired',
      title: 'Hired Gigger Feedback',
      nav: [],
    },
    deleteAccount: {
      url: '/customer-deleteaccount',
      title: 'Delete Account',
      nav: [],
    },
    users: {
      url: '/admin/portal/users-list',
      title: 'Users',
      nav: [],
    },
    orders: {
      url: '/admin/portal/order-list',
      title: 'Orders',
      nav: [],
    },
    portal: {
      url: '/admin/portal',
      title: 'Portal',
      nav: [],
    },
    giggers: {
      url: '/admin/portal/gigger-list',
      title: 'Giggers',
      nav: [],
    },
    filter: {
      url: '/admin/portal/filter-list',
      title: 'Filter',
      nav: [],
    },
    feedback: {
      url: '/admin/portal/feedback-list',
      title: 'Feedback',
      nav: [],
    },
    company: {
      url: '/admin/portal/company-list',
      title: 'Company',
      nav: [],
    },
    adminGiggerDetailView: {
      url: '/admin/portal/gigger/:giggername/profile',
      title: 'Gigger Details',
      nav: [],
    },
    adminCompanyDetailView: {
      url: '/admin/portal/company/:companyname/profile',
      title: 'Company Details',
      nav: [],
    },
    thankYouForFeedback: {
      url: '/thank-you',
      title: 'Feedback',
      nav: [],
    },
    thankYouForHiring: {
      url: '/thank-hiring-message',
      title: 'Thank You',
      nav: [],
    },
    notFound: {
      url: '/not-found',
      title: 'not-found',
      nav: [],
    },
    CompanyFeedback: {
      url: '/customer-feedback',
      title: 'Company Feedback',
      nav: [],
    },
    groups: {
      url: '/admin/portal/groups',
      title: 'Groups',
      nav: [],
    },
    groupsDetails: {
      url: '/admin/portal/groups/:id',
      title: 'Groups',
      nav: [],
    },
  };

  const routesList = Object.entries(routes).map((obj) => obj[1]);
  // const route = routesList.find((obj) => window.location.pathname.indexOf(obj.url) > -1);
  const routePath = window.location.pathname.split('/');
  const routePathName = `/${routePath[routePath.length - 1]}`;
  const route = routesList.find((obj) => obj.url.includes(routePathName));
  if (route) {
    document.title = Constants.ApplicationName + Constants.TitleDivder + route.title;
  }
  if (
    routePathName === Constants.routes.giggerSignUp.url
    // || routePathName === Constants.routes.customerSignUp.url
    || routePathName === Constants.routes.logOut.url
  ) {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
  }
  const isAuthenticated = authService.isAuthenticated() === true;
  const sidemenuRoute = window.location.pathname;
  const isAdminPortal = sidemenuRoute.search(Constants.routes.portal.url) >= 0;

  return (
    <div>
      <div className={`${isAuthenticated ? (isAdminPortal ? 'row' : '') : 'w-100'} h-100  m-0`} id="main-wrapper">
        {isAuthenticated && isAdminPortal ? (
          <>
            <div className={isAuthenticated ? 'col sideMenu-panel p-0' : ''}>
              <SideMenu route={route} />
            </div>
            <div className={isAuthenticated ? 'col main-panel  mh-100 h-100 p-0' : 'h-100'}>{children}</div>
          </>
        ) : (
          <>
            <Header />
            <div className={isAuthenticated ? 'col main-panel  mh-100 h-100 p-0' : 'h-100'}>{children}</div>
            <Footer />
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(withRouter(Container));
