import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import validator from 'validator';
import TextBox from '../../../components/Input/TextBox';
import {
  CancelButton,
  Button,
  Input,
} from '../../../components/Modals/styles';

const RenameGroupModal = ({
  name = '', onModalClose, handleOnSubmit,
}) => {
  const [groupName, setGroupname] = useState(name);
  const [isDisabled, setIsDisabled] = useState(false);

  const closeModal = useRef(null);

  const handleChange = (event) => {
    const { value } = event?.target;
    setGroupname(value);
    setIsDisabled(!value);
  };

  const handleSubmit = () => {
    handleOnSubmit(groupName);
  };

  return (
    <>
      <Input>
        <TextBox
          type="text"
          name="groupName"
          value={groupName}
          label={<FormattedMessage id="label_group_name" defaultMessage="Group name" />}
          onChange={handleChange}
          errorMessage={<FormattedMessage id="error_group_name_message" defaultMessage="Enter group name" />}
          rule={{
            method: validator.isEmpty,
            validWhen: false,
          }}
        />
      </Input>
      <Input className="mt-4">
        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          data-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage id="common_ok" defaultMessage="Ok" />
        </Button>
        <CancelButton
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
          ref={closeModal}
        >
          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
        </CancelButton>
      </Input>
    </>
  );
};

export default RenameGroupModal;
