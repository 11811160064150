import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import validator from 'validator';
import {
  Card,
  CancelButton,
  Button,
  Header,
  Section,
  Content,
  Span,
  Input,
  Text,
  HeaderText,
  CommentsBlock,
} from '../../../components/Modals/styles';
import Constants from '../../../shared/constants';
import GiggerService from '../../../services/AdminServices/GiggersService';
import UserService from '../../../services/AdminServices/UserService';
import Config from '../../../config';
import CheckBox from '../../../components/Input/CustomCheckbox';
import Select from '../../../components/Input/Select';
import { SubHeader } from '../../profile/styles';
import CommentBox from '../../../components/Input/TextArea';
import { Border } from '../../../components/Card/styles';
import Fonts from '../../../styles/Fonts';
import { WhiteButton } from '../../customer/styles';
import ModalLoader from '../../../components/Loader/ModalLoader';
import { isAdmin } from '../../../shared/utils';
import MultiSelect from '../../../components/Input/MultiSelect';
import GroupsService from '../../../services/AdminServices/GroupsService';

class Modal extends Component {
  giggerService = new GiggerService(Config.apiBase);

  userService = new UserService(Config.apiBase);

  groupsService = new GroupsService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      assignOwnerOptions: [{ label: 'None', value: '' }],
      groupOptions: [{ label: 'None', value: '' }],
      groups: [],
      owner: '',
      ownerId: '',
      label: '',
      approveFilters: [],
      pendingFilters: [],
      manageCVOptions: [
        {
          label: Constants.language.common_not_started,
          value: Constants.status.NOTSTARTED,
        },
        {
          label: Constants.language.common_not_checked,
          value: Constants.status.NOTCHECKED,
        },
        { label: Constants.language.common_approved, value: Constants.status.CV_APPROVED },
        {
          label: Constants.language.common_disapproved,
          value: Constants.status.CV_DISAPPROVED,
        },
        {
          label: Constants.language.common_followed_up,
          value: Constants.status.FOLLOWEDUP,
        },
        {
          label: Constants.language.common_edited,
          value: Constants.status.EDITED,
        },
      ],
      comment: '',
      isAddCommentSelected: false,
      cvStatus: '',
      manageCvFormvalid: {
        status_isValid: false,
        comment_isValid: false,
      },
      isDisabled: true,
      isApproveProfileDisabled: false,
      accountStatus: '',
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const {
      assignOwner,
      approveProfile,
      currentAccountOwner,
      currentLabel,
      addLabel,
      manageCV,
      status,
      idStatus,
      accountStatus = '',
      addGroup,
      currentGroup = [],
    } = this.props;
    const { pendingFilters, manageCVOptions } = this.state;
    if (addGroup) {
      let { groupOptions } = this.state;
      const queryParams = [
        ['export', true],
      ];
      const response = await this.groupsService.getGroupsList(queryParams);
      if (response) {
        const { data: groupData = [] } = response;
        groupOptions = groupData.map((obj) => (
          {
            label: obj.name,
            value: obj.id,
            isSelected: currentGroup.includes(obj.id),
          }
        ));
        this.setState({ groupOptions, groups: currentGroup });
      }
    }
    if (assignOwner) {
      const { assignOwnerOptions } = this.state;
      const queryParams = [
        ['pageSize', 100],
        ['role', Constants.role.Admin],
      ];
      const response = await this.userService.getUsersList(queryParams);
      if (response) {
        response.data.map((obj) => assignOwnerOptions.push({
          label: obj.full_name,
          value: obj.id,
        }));

        this.setState({ assignOwnerOptions, owner: currentAccountOwner });
      }
    }
    if (approveProfile) {
      const { data = {} } = this.props;
      const {
        jobtitle = [], category = [], skills = [], systems = [],
      } = data;

      const pendingGiggerFilters = [
        jobtitle.map((jt) => ({ ...jt, type: Constants.filter.JOBTITLE })),
        category.map((cat) => ({ ...cat, type: Constants.filter.CATEGORY })),
        skills.map((skill) => ({ ...skill, type: Constants.filter.SKILLS })),
        systems.map((system) => ({ ...system, type: Constants.filter.SYSTEMS })),
      ]
        .flat()
        .filter((obj) => obj.status === Constants.status.PENDING);
      const approveFilters = pendingFilters.filter((pf) => pf.isSelected);
      const isApproveProfileDisabled = pendingGiggerFilters.filter(
        (obj) => obj.type === Constants.filter.JOBTITLE,
      )
        .length;
      this.setState({
        pendingFilters: pendingGiggerFilters,
        approveFilters,
        isApproveProfileDisabled,
      });
    }
    if (addLabel) {
      this.setState({ label: currentLabel });
    }
    if (manageCV) {
      let options = [];
      if (!isAdmin) {
        options = manageCVOptions.filter(
          (obj) => obj.value === Constants.status.CV_APPROVED
            || obj.value === Constants.status.CV_DISAPPROVED,
        );
      } else {
        options = manageCVOptions;
      }
      this.setState({ cvStatus: status, manageCVOptions: options });
    }
    if (idStatus && accountStatus === Constants.status.IDDISAPPROVE) {
      this.setState({ accountStatus: Constants.language.common_disapprove });
    }
    this.setState({ loading: false });
  };

  handleApproveAllFilter = (value) => () => {
    let { pendingFilters } = this.state;
    pendingFilters = pendingFilters.map((obj, index) => {
      if (index === value) {
        return { ...obj, isSelected: !obj.isSelected };
      }
      return obj;
    });

    this.setState(
      { pendingFilters, approveFilters: pendingFilters.filter((pf) => pf.isSelected) },
      this.validateJobTitle,
    );
  };

  /**
   * Validates job title while approving a profile.
   */
  validateJobTitle = () => {
    let error = false;
    const { pendingFilters } = this.state;
    pendingFilters.filter((obj) => {
      if (obj.type === Constants.filter.JOBTITLE) {
        error = true;
        if (obj.isSelected) {
          error = !obj.isSelected;
        }
        return error;
      }
      return '';
    });
    this.setState({ isApproveProfileDisabled: error });
  };

  /**
   * Validation among filters
   */
  validateFilters = () => {
    let error = false;
    const valid = [];
    const { pendingFilters } = this.state;
    if (pendingFilters && pendingFilters.length > 0) {
      pendingFilters.map((obj) => {
        error = obj.isSelected ? obj.isSelected : false;
        return valid.push(error);
      });
    } else {
      valid.push(!error);
    }
  };

  /**
   * @async
   * @function handleapprove Approves the gigger profile and filters
   */
  handleapprove = async () => {
    this.setState({ loading: true });
    const {
      id, handleToast, ModalClose, setIsProfileBeingApproved,
    } = this.props;
    const { approveFilters } = this.state;
    const data = {
      approveFilters: {
        value: approveFilters,
      },
    };
    setIsProfileBeingApproved(true);
    try {
      const response = await this.giggerService.approveGigger(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        ModalClose();
        setIsProfileBeingApproved(false);
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_profile_approved,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      ModalClose();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * @async
   * @function handleChangeIdStatus Handle change in id status
   */
  handleChangeIdStatus = async () => {
    this.setState({ loading: true });
    const { id, handleLoad, handleToast } = this.props;
    const { accountStatus } = this.state;
    const data = {
      status: accountStatus,
    };
    try {
      const response = await this.giggerService.approveGigger(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_account_status_updated,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * Handles change in select input field
   * @param {string} name Name of the input field
   * @param {string} value Value of the input field
   * @param {string} id Id of the input field
   */
  handleSelectChange = (name, value, id) => {
    this.setState({ [name]: value, ownerId: id });
  };

  handleOnGroupChange = (name, label, value) => {
    this.setState({ [name]: { label, value } });
  };

  handleAddGroupsToGigger = async () => {
    const { groups } = this.state;
    const {
      id, handleToast, handleLoad,
    } = this.props;
    const data = { group_id: groups?.value?.split(',') };
    try {
      const response = await this.giggerService.updateGigger(id, data);
      if (response) {
        this.closeElement.click();
        handleLoad();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_update_gigger_group,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * Handles change in label
   * @param {string} name Name of the input field
   * @param {string} value Value of the input field
   * @param {string} id Id of the input field
   */
  handleLabelChange = (name, label, value) => {
    this.setState({ [name]: value }, this.validatemanageCvForm);
  };

  /**
   * @async
   * @function handleAssignOwner Changes the giggers account owner
   */
  handleAssignOwner = async () => {
    this.setState({ loading: true });
    const { id, handleToast, handleLoad } = this.props;
    const { ownerId } = this.state;

    const data = { accountOwner: ownerId };
    try {
      const response = await this.giggerService.updateGigger(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_owner_updated,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * @async
   * @function handleAddlabel Adds or changes the label of the gigger
   */
  handleAddlabel = async () => {
    this.setState({ loading: true });
    const { id, handleToast, handleLoad } = this.props;
    const { label } = this.state;
    const data = { label: label === 'None' ? '' : label };
    try {
      const response = await this.giggerService.updateGigger(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
        handleToast({
          type: Constants.toast.SUCCESS,
          message: Constants.language.toast_label_added,
          delay: Constants.toast.DELAY,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * @async
   * @function handleRemoveAccount
   * Removes gigger account
   */
  handleRemoveAccount = async () => {
    this.setState({ loading: true });
    const { id, history, handleToast } = this.props;
    try {
      const response = await this.giggerService.removeGigger(id);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        history.replace(Constants.routes.giggers.url);
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleToast({
        type: Constants.toast.ERROR,
        message: Constants.language.toast_try_after_time,
        delay: Constants.toast.DELAY,
      });
    }
  };

  /**
   * Handles change in input value
   * @param {event} event comments to reject the cv.
   */
  handleMessageChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, this.validatemanageCvForm);
  };

  /**
   * Checks add comment check box
   */
  handleAddComment = () => {
    const { isAddCommentSelected } = this.state;
    this.setState({ isAddCommentSelected: !isAddCommentSelected });
  };

  /**
   * @async
   * @function handleMangeCv Saves the cv status
   */
  handleMangeCv = async () => {
    this.setState({ loading: true });
    const { cvStatus, comment } = this.state;
    const { id, handleLoad } = this.props;
    const data = {
      status: cvStatus,
      comment: { value: comment },
    };
    try {
      const response = await this.giggerService.updateCvStatus(id, data);
      if (response) {
        this.setState({ loading: false });
        this.closeElement.click();
        handleLoad();
      }
    } catch (e) {
      this.setState({ loading: false });
      this.closeElement.click();
      handleLoad();
    }
  };

  /**
   * Validates the manage cv form
   */
  validatemanageCvForm = () => {
    const { cvStatus, comment, manageCvFormvalid } = this.state;
    const { status } = this.props;
    let error = false;
    if (validator.isEmpty(cvStatus)) {
      manageCvFormvalid.status_isValid = false;
    } else {
      manageCvFormvalid.status_isValid = true;
    }
    if (
      status === Constants.status.NOTCHECKED
      && validator.isEmpty(comment)
      && cvStatus === Constants.status.CV_DISAPPROVED
    ) {
      manageCvFormvalid.comment_isValid = false;
    } else {
      manageCvFormvalid.comment_isValid = true;
    }
    Object.keys(manageCvFormvalid).map((obj) => {
      if (manageCvFormvalid[obj] === false) {
        error = true;
      }
      return error;
    });
    this.setState({ isDisabled: error });
  };

  render() {
    const {
      remove,
      approveProfile,
      approve,
      assignOwner,
      addLabel,
      ModalClose,
      manageCV,
      manageCVData,
      idStatus,
      addGroup,
    } = this.props;
    const {
      labelOptions,
      assignOwnerOptions,
      owner,
      label,
      pendingFilters,
      manageCVOptions,
      comment,
      cvStatus,
      isDisabled,
      isApproveProfileDisabled,
      accountStatus,
      loading,
      groupOptions,
      groups,
    } = this.state;

    return (
      <>
        <div
          className="modal fade bd-example-modal-sm fade-profile-modal"
          id="GiggerModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modal"
          aria-hidden="true"
        >
          <div className={`${manageCV ? 'top-12-rem' : ''} modal-dialog modal-small`} role="document">
            <div className="modal-content contracts-modal bg-green rounded border-0">
              <div className="modal-body rounded p-0 m-0">
                <Card widthFixed={manageCV ? '508px' : '100%'} className={manageCV ? 'ml-4 pl-2 pr-3' : ''}>
                  {!loading && (
                    <Section className="row m-0">
                      <Content className="col">
                        {!(remove || approve) && (
                          <Header>
                            {manageCV && <FormattedMessage id="admin_header_manage_cv" defaultMessage="Manage CV" />}
                            {idStatus && <FormattedMessage id="modal_header_id_status" defaultMessage="Id status" />}
                            {approveProfile ? (
                              <FormattedMessage id="admin_gigger_approve_profile" defaultMessage="Approve profile?" />
                            ) : assignOwner ? (
                              <FormattedMessage
                                id="admin_gigger_assign_account_owner"
                                defaultMessage="Assign account owner"
                              />
                            ) : addLabel ? (
                              <FormattedMessage id="admin_gigger_add_label" defaultMessage="Add Label" />
                            ) : (
                              ''
                            )}
                            {addGroup && <FormattedMessage id="label_add_gigger_to_group" defaultMessage="Add gigger to group" />}
                          </Header>
                        )}
                      </Content>
                      <Content message className="col-auto">
                        <Span>
                          <button
                            type="button"
                            className="close font-weight-normal"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={ModalClose}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </Span>
                      </Content>
                    </Section>
                  )}
                  {remove && !loading && (
                    <>
                      <Text>
                        <FormattedMessage
                          id="admin_company_remove_account_confirmation_message"
                          defaultMessage="Are you sure you want to delete?"
                        />
                      </Text>
                      <Input>
                        <Button onClick={this.handleRemoveAccount}>
                          <FormattedMessage id="common_yes_delete" defaultMessage="Yes, delete" />
                        </Button>
                        <CancelButton
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                        >
                          <FormattedMessage id="common_no" defaultMessage="No" />
                        </CancelButton>
                      </Input>
                    </>
                  )}
                  {approve && !loading && (
                    <>
                      <Text>
                        <FormattedMessage
                          id="approve_filter_confirmation_message"
                          defaultMessage="Are you sure you want to approve?"
                        />
                      </Text>
                      <Input>
                        <Button onClick={this.handleApproveFilter}>
                          <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
                        </Button>
                        <CancelButton
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                        >
                          <FormattedMessage id="common_no" defaultMessage="No" />
                        </CancelButton>
                      </Input>
                    </>
                  )}
                  {addLabel && !loading && (
                    <>
                      <Input>
                        <Select
                          name="label"
                          placeholder={<FormattedMessage id="common_none" defaultMessage="None" />}
                          value={label ? Constants.translateLabels[label.toLowerCase()] : ''}
                          onChange={this.handleLabelChange}
                          errorMessage={
                            <FormattedMessage id="admin_gigger_select_a_label" defaultMessage="Select a label" />
                          }
                          options={labelOptions}
                        />
                      </Input>
                      <Input>
                        <Button onClick={this.handleAddlabel}>
                          <FormattedMessage id="common_ok" defaultMessage="Ok" />
                        </Button>
                        <CancelButton
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                        >
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>
                      </Input>
                    </>
                  )}
                  {assignOwner && !loading && (
                    <>
                      <Input>
                        <Select
                          name="owner"
                          label={<FormattedMessage id="common_owner" defaultMessage="Owner" />}
                          value={owner}
                          placeholder={<FormattedMessage id="common_none" defaultMessage="None" />}
                          errorMessage={
                            <FormattedMessage id="error_account_owner_message" defaultMessage="Select account owner" />
                          }
                          onChange={this.handleSelectChange}
                          options={assignOwnerOptions}
                        />
                      </Input>
                      <Input>
                        <Button onClick={this.handleAssignOwner}>
                          <FormattedMessage id="common_assign" defaultMessage="Assign" />
                        </Button>
                        <CancelButton
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                        >
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>
                      </Input>
                    </>
                  )}
                  {approveProfile && !loading && (
                    <>
                      {/* <Input> */}
                      <Text className="mt-2">
                        <FormattedMessage
                          id="admin_company_approve_confirmation_message"
                          defaultMessage="By approving, this profile will get published and you will become the account owner for
                          gigger. You can change that later."
                        />
                      </Text>
                      {/* </Input> */}
                      {pendingFilters.length > 0 && (
                        <Input>
                          <HeaderText>
                            <FormattedMessage id="admin_gigger_add_new_filter" defaultMessage="Add new filter option" />
                          </HeaderText>
                          {pendingFilters.filter((jt) => jt.type
                            === Constants.filter.JOBTITLE).length > 0 && (
                              <HeaderText>
                                <FormattedMessage id="common_job_title" defaultMessage="Job title" />
                                *
                              </HeaderText>
                          )}
                          {pendingFilters.map((obj, index) => {
                            if (obj.type === Constants.filter.JOBTITLE) {
                              return (
                                <div key={obj.value} className="mb-2">
                                  <CheckBox
                                    isSelected={obj.isSelected ? obj.isSelected : false}
                                    label={obj.name}
                                    onClick={this.handleApproveAllFilter(index)}
                                  />
                                </div>
                              );
                            }
                            return '';
                          })}
                          {pendingFilters.filter((skill) => skill.type
                            === Constants.filter.SKILLS).length > 0 && (
                              <HeaderText>
                                <FormattedMessage id="common_skills" defaultMessage="Skills" />
                              </HeaderText>
                          )}
                          {pendingFilters.map((obj, index) => {
                            if (obj.type === Constants.filter.SKILLS) {
                              return (
                                <div key={obj.value} className="mb-2">
                                  <CheckBox
                                    isSelected={obj.isSelected ? obj.isSelected : false}
                                    label={obj.name}
                                    onClick={this.handleApproveAllFilter(index)}
                                  />
                                </div>
                              );
                            }
                            return '';
                          })}
                          {pendingFilters.filter((sys) => sys.type
                            === Constants.filter.SYSTEMS).length > 0 && (
                              <HeaderText>
                                <FormattedMessage id="common_systems" defaultMessage="Systems" />
                              </HeaderText>
                          )}
                          {pendingFilters.map((obj, index) => {
                            if (obj.type === Constants.filter.SYSTEMS) {
                              return (
                                <div key={obj.value} className="mb-2">
                                  <CheckBox
                                    isSelected={obj.isSelected ? obj.isSelected : false}
                                    label={obj.name}
                                    onClick={this.handleApproveAllFilter(index)}
                                  />
                                </div>
                              );
                            }
                            return '';
                          })}
                        </Input>
                      )}
                      <Input>
                        <Button onClick={this.handleapprove} disabled={isApproveProfileDisabled}>
                          <FormattedMessage id="common_yes_approve" defaultMessage="Yes, approve" />
                        </Button>
                        <CancelButton
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                        >
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>
                      </Input>
                    </>
                  )}

                  {idStatus && !loading && (
                    <>
                      <Text className="mt-2">
                        <FormattedMessage
                          id="modal_id_status_description"
                          defaultMessage="Are you sure you want to approve?"
                        />
                      </Text>
                      <Input>
                        <Select
                          name="accountStatus"
                          placeholder={<FormattedMessage id="select_placeholder_choose" defaultMessage="Choose" />}
                          value={accountStatus}
                          onChange={this.handleLabelChange}
                          errorMessage={<FormattedMessage id="select_error_status" defaultMessage="Select a status" />}
                          options={Constants.statusOptions}
                        />
                      </Input>
                      <Input>

                        <Button
                          disabled={!accountStatus.length}
                          onClick={this.handleChangeIdStatus}
                        >
                          <FormattedMessage id="common_save" defaultMessage="Save" />
                        </Button>

                        <CancelButton
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>

                      </Input>
                    </>
                  )}

                  {manageCV && !loading && (
                    <>
                      {manageCVData && manageCVData.length > 0 && (
                        <>
                          <CommentsBlock className="scroll">
                            {manageCVData
                              && manageCVData.map((data, index) => (
                                <>
                                  {data && data.comment && (
                                    <div className="mr-2">
                                      <Input className="mt-3">
                                        <Text>
                                          {data.commentedBy && data.commentedBy.name}
                                          {'  ('}
                                          {data.createdTime}
                                          {')'}
                                        </Text>
                                        <Text
                                          className="font-weight-light"
                                          style={{ fontFamily: `${Fonts.fontFamily.AvenirLight}` }}
                                        >
                                          {data.comment}
                                        </Text>
                                      </Input>
                                      {index !== manageCVData.length - 1 && <Border className="opacity-5 " />}
                                    </div>
                                  )}
                                </>
                              ))}
                          </CommentsBlock>
                          <Border className="mt-4" />
                        </>
                      )}
                      <Input className="mb-4">
                        <SubHeader>
                          <FormattedMessage id="common_status" defaultMessage="Status" />
                        </SubHeader>
                        <Select
                          name="cvStatus"
                          value={Constants[cvStatus]}
                          onChange={this.handleLabelChange}
                          options={manageCVOptions}
                          noEmptyError
                        />
                      </Input>
                      <Input className="mt-0">
                        <CommentBox
                          type="summary"
                          name="comment"
                          label={<FormattedMessage id="common_message" defaultMessage="Message" />}
                          value={comment}
                          onChange={this.handleMessageChange}
                          errorMessage={(
                            <FormattedMessage
                              id="disapprove_error_message"
                              defaultMessage="Please write a short message"
                            />
                              )}
                          existErrorMessage={(
                            <FormattedMessage
                              id="disapprove_error_message"
                              defaultMessage="Please write a short message"
                            />
                              )}
                        />
                      </Input>
                      <div className="row m-0">
                        <Input className="col-auto pl-0">
                          <Button disabled={isDisabled} onClick={this.handleMangeCv}>
                            <FormattedMessage id="common_ok" defaultMessage="Ok" />
                          </Button>
                        </Input>
                        <Input className="col-auto pl-0">
                          <WhiteButton
                            onClick={ModalClose}
                            data-dismiss="modal"
                            aria-label="Close"
                            className="px-4 mx-2 py-2 text-decoration-none"
                            ref={(closeModal) => {
                              this.closeElement = closeModal;
                            }}
                          >
                            <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                          </WhiteButton>
                        </Input>
                      </div>
                    </>
                  )}
                  {addGroup && !loading && (
                    <>
                      <Input>
                        <MultiSelect
                          forFilter
                          showSearch
                          className="mb-4"
                          label={<FormattedMessage id="label_group" defaultMessage="Group" />}
                          placeholder={<FormattedMessage id="select_placeholder_choose" defaultMessage="Choose..." />}
                          name="groups"
                          value={groups}
                          options={groupOptions}
                          onChange={this.handleOnGroupChange}
                          noEmptyError
                        />
                      </Input>

                      <Input>
                        <Button
                          disabled={!groups?.value}
                          onClick={this.handleAddGroupsToGigger}
                        >
                          <FormattedMessage id="common_save" defaultMessage="Save" />
                        </Button>

                        <CancelButton
                          onClick={ModalClose}
                          ref={(closeModal) => {
                            this.closeElement = closeModal;
                          }}
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
                        </CancelButton>

                      </Input>
                    </>
                  )}

                  {loading && <ModalLoader />}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Modal);
