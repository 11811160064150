import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Border,
  TableValue,
  OptionButton,
  TableData,
  Wrapper,
} from '../../users/styles';
import Modal from '../Modal';
import { Line } from '../../company/styles';
import { ButtonWrapper } from '../styles';
import Constants from '../../../../shared/constants';
import Button from '../../../../components/Button/Button';
import { WhiteButton } from '../../../customer/styles';
import { WhiteCard } from '../../../gigger/styles';
import OrderAccordion from '../../../../components/Accordion/AccordionBody';

class ListViewBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openColumn: '',
      orderData: [],
      isCmpltOrderModalOpen: false,
      isInvoiceModalOpen: false,
      isCancelModalOpen: false,
      isDeleteModalOpen: false,
    };
  }

  /**
   * Handles accordion data
   * @param {Number} value row index
   * @param {Object} data row data
   */
  handleOptions = (value, data) => () => {
    const { openColumn } = this.state;

    if (openColumn === value) {
      this.setState({ openColumn: '' });
    } else {
      this.setState({ openColumn: Number(value), orderData: data });
    }
  };

  /**
   * Opens complete order modal
   */
  handleCompleteOrderModalOpen = () => {
    this.setState({ isCmpltOrderModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens invoice order modal
   */
  handleInvoiceModalOpen = () => {
    this.setState({ isInvoiceModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens cancel order modal
   */
  handleCancelModalOpen = () => {
    this.setState({ isCancelModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens cancel order modal
   */
   handleDeleteModalOpen = () => {
     this.setState({ isDeleteModalOpen: true }, () => {
       this.openElement.click();
     });
   };

  /**
   * Closes all modals
   */
  handleModalClose = () => {
    this.setState({
      isCmpltOrderModalOpen: false,
      isInvoiceModalOpen: false,
      isCancelModalOpen: false,
    });
  };

  /**
   * Loads data
   */
  handleLoad = () => {
    const { handleLoad } = this.props;
    this.handleModalClose();
    handleLoad();
  };

  render() {
    const {
      data = [],
      gigger,
      admin,
      customer,
      searchQuery,
    } = this.props;
    const {
      orderData = [],
      openColumn,
      isCmpltOrderModalOpen,
      isInvoiceModalOpen,
      isCancelModalOpen,
      isDeleteModalOpen,
    } = this.state;

    return (
      <>
        {data.length > 0 ? (
          <>
            {data.map((order, index) => (
              <>
                <Border />
                <Wrapper className="row m-0">
                  {!customer && (
                  <TableData className="col text-truncate" title={order.company_name}>
                    <Link
                      to={{
                        pathname: `/admin/portal/company/${order.company_id}/profile`,
                        query: { id: order._id },
                      }}
                    >
                      <Line>
                        <TableValue>{order.company_name}</TableValue>
                      </Line>
                    </Link>
                  </TableData>
                  )}

                  <TableData className="col text-truncate" title={order.company_user_name}>
                    <TableValue>
                      {order.company_user_name ? order.company_user_name : '-'}
                    </TableValue>
                  </TableData>

                  {!gigger && (
                  <TableData className="col text-truncate" title={order.gigger_name}>
                    <Link
                      to={{
                        pathname: `/admin/portal/gigger/${order.gigger_id}/profile`,
                        query: { id: order._id },
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Line>
                        <TableValue>{order.gigger_name}</TableValue>
                      </Line>
                    </Link>
                  </TableData>
                  )}

                  <TableData className="col">
                    <TableValue>{moment(order.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')}</TableValue>
                  </TableData>

                  <TableData className="col text-truncate" title={Constants.ORDER_TYPE[order.type]}>
                    <TableValue>{Constants.ORDER_TYPE[order.type]}</TableValue>
                  </TableData>
                  {!admin && (
                  <TableData className="col text-truncate" title={order.amount}>
                    <TableValue>
                      {`${order.amount} kr`}
                    </TableValue>
                  </TableData>
                  )}

                  {customer && (
                  <TableData className="col text-truncate" title={order._id}>
                    <TableValue>{order._id}</TableValue>
                  </TableData>
                  )}
                  <TableData className="col-auto text-align">
                    <TableValue>
                      <ButtonWrapper>
                        {order.status === Constants.status.OPEN ? (
                          <Button
                            name={<FormattedMessage id="common_open" defaultMessage="Open" />}
                            type={Constants.status.OPEN}
                          />
                        ) : order.status === Constants.status.CANCELLED ? (
                          <Button
                            name={<FormattedMessage id="common_cancelled" defaultMessage="Cancelled" />}
                            type={Constants.status.CANCELLED}
                          />
                        ) : order.status === Constants.status.COMPLETED ? (
                          <Button
                            name={<FormattedMessage id="common_complete" defaultMessage="Complete" />}
                            type={Constants.status.COMPLETE}
                          />
                        ) : (
                          <Button
                            name={<FormattedMessage id="common_invoiced" defaultMessage="Invoiced" />}
                            type={Constants.status.APPROVE}
                          />
                        )}
                      </ButtonWrapper>
                    </TableValue>
                  </TableData>

                  <TableData className="col-auto">
                    <OptionButton>
                      <TableValue>
                        <WhiteButton onClick={this.handleOptions(index, order)}>
                          <img
                            src={Constants.icons.ArrowDown}
                            alt="icon"
                            className={openColumn === index ? 'rotate-180 cursor-pointer' : 'cursor-pointer'}
                          />
                        </WhiteButton>
                      </TableValue>
                    </OptionButton>
                  </TableData>
                  <OrderAccordion
                    order
                    animate={openColumn === index}
                    data={order}
                    status={order.status}
                    handleLoad={this.handleLoad}
                    completeOrder={this.handleCompleteOrderModalOpen}
                    invoiceOrder={this.handleInvoiceModalOpen}
                    cancelOrder={this.handleCancelModalOpen}
                    deleteOrder={this.handleDeleteModalOpen}
                  />
                </Wrapper>
              </>
            ))}
          </>
        ) : (
          <>
            <Border />
            <WhiteCard className="my-3">
              {searchQuery && searchQuery.length > 0 ? (
                <FormattedMessage id="common_no_search_results_found" defaultMessage="No search results found" />
              ) : (
                <FormattedMessage id="common_no_orders_found" defaultMessage="No orders found" />
              )}
            </WhiteCard>
          </>
        )}
        <a
          href
          data-toggle="modal"
          data-target="#OrderModal"
          data-backdrop="static"
          data-keyboard="false"
          ref={(openModal) => { this.openElement = openModal; }}
        />
        {isCmpltOrderModalOpen && (
        <Modal
          completeOrder={isCmpltOrderModalOpen}
          id={orderData._id}
          data={orderData}
          ModalClose={this.handleModalClose}
          updateOrdersList={this.handleLoad}
        />
        )}
        {isInvoiceModalOpen && (
        <Modal
          invoiceOrder={isInvoiceModalOpen}
          id={orderData._id}
          ModalClose={this.handleModalClose}
          updateOrdersList={this.handleLoad}
        />
        )}
        {isCancelModalOpen && (
        <Modal
          cancel={isCancelModalOpen}
          id={orderData._id}
          ModalClose={this.handleModalClose}
          updateOrdersList={this.handleLoad}
        />
        )}
        {isDeleteModalOpen && (
          <Modal
            remove={isDeleteModalOpen}
            id={orderData._id}
            type={Constants.filter.SYSTEMS}
            ModalClose={this.handleModalClose}
            // handleToast={handleToast}
            updateOrdersList={this.handleLoad}
          />
        )}
      </>
    );
  }
}
export default ListViewBody;
