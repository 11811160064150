import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Font from '../../styles/Fonts';

export const Card = styled.div`
  width: ${({ widthFixed }) => (widthFixed || '100%')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.message ? '#f9e3d6' : '#ffffff')};
  display: block;
  margin-bottom:${({ widthFixed }) => (widthFixed ? '0px' : '11px')};
  padding: 24px;
  border-radius: 2px;
  @media (max-width:768px) {
    width: 100% !important;
  }
`;
export const Header = styled.h1`
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.Primary};
`;
export const Text = styled(Header)`
  font-size: ${Font.fontSize.Size14}px;
  letter-spacing: 0.56px;
`;
export const HeaderText = styled(Text)`
  font-weight: 900;
  font-family: ${Font.fontFamily.AvenirBold};
`;
export const Button = styled.button`
  border-radius: 2px;
  background-color: ${Colors.Primary};
  width: ${({ width }) => (`${width}px` || 'auto')};
  padding: ${({ padding }) => (padding || '8px 35px')};
  font-family: ${Font.fontFamily.AvenirRegular};
  font-size: ${Font.fontSize.Size16}px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${Colors.White};
  border: 2px solid #0e3132;
  outline: none;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #000000;
    border: 2px solid #000000;
  }
  &:focus,
  &:active {
    outline: none;
    border: 2px solid #0e3132;
  }
  @media (max-width:570px){
    width: 100%;
  }
`;
export const CancelButton = styled(Button)`
  margin-left: 13px;
  background-color: ${Colors.White};
  color: ${Colors.Primary} !important;
  border: 2px solid #0e3132;
  &:hover {
    background-color: #f1f5f7;
    border: 2px solid #000000;
  }
  &:focus,
  &:active {
    outline: none;
    border: 2px solid #0e3132;
  }
  @media (max-width:570px) {
    width: 100%;
    margin:16px 0px 0px 0px;
  }
`;
export const Section = styled.div`
  padding: 0px;
`;
export const Content = styled(Section)``;
export const Span = styled.span`
  font-size: ${Font.fontSize.Size18}px;
  font-weight: 500;
  font-family: ${Font.fontFamily.AvenirMedium};
  line-height: 27px;
  letter-spacing: normal;
  color: ${Colors.Primary};
  cursor: pointer;
  &:hover {
    color: ${Colors.Black};
  }
`;
export const Input = styled.div`
  max-width: 380px;
  margin-top: 24px;
`;
export const UploadDocument = styled.div`
  height: 42px;
  border-style: dashed !important;
  border: solid 1px rgb(127, 125, 152, 0.5);
  text-align: center;
  color: ${Colors.Primary};
`;
export const CloseButton = styled.a`
  text-decoration: underline !important;
  font-size: ${Font.fontSize.Size16}px;
  color: ${Colors.Secondary};
`;
export const DragAndDrop = styled.input`
  display: none;
`;
export const OpenModal = styled.u``;
export const StarIcon = styled.img`
  width: 22px;
  heigth: 22px;
`;
export const CommentsBlock = styled.div`
  max-height:224px;
  overflow-Y: auto;
`;
