import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CancelButton,
  Button,
  Input,
  Text,
} from '../../../components/Modals/styles';

const DeleteMemberModal = ({
  onModalClose, handleOnSubmit,
}) => {
  const closeModal = useRef(null);

  return (
    <>
      <Text className="mt-2">
        <FormattedMessage id="des_delete_member" defaultMessage="Are you sure you want to delete this member from this group? This action can't be undone." />
      </Text>
      <Input className="mt-4">
        <Button
          onClick={handleOnSubmit}
          data-dismiss="modal"
          aria-label="Close"
          padding="8px 20px"
        >
          <FormattedMessage id="delete_member" defaultMessage="Delete member" />
        </Button>
        <CancelButton
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
          ref={closeModal}
        >
          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
        </CancelButton>
      </Input>
    </>
  );
};

export default DeleteMemberModal;
