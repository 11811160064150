import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CancelButton,
  Button,
  Input,
} from '../../../components/Modals/styles';
import MultiSelect from '../../../components/Input/MultiSelect';

const AddGiggersToGroupModal = ({
  giggerList, onModalClose, handleOnSubmit,
}) => {
  const [giggers, setGiggers] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const closeModal = useRef(null);

  const handleOnGiggerChange = (name, label, value) => {
    setGiggers({ label, value });
    setIsDisabled(!value);
  };

  const handleSubmit = () => {
    handleOnSubmit(giggers);
  };

  return (
    <>
      <Input>
        <MultiSelect
          forFilter
          showSearch
          className="mb-4"
          label={<FormattedMessage id="label_members" defaultMessage="Members" />}
          placeholder={<FormattedMessage id="choose_members" defaultMessage="Choose members" />}
          name="giggers"
          options={giggerList}
          onChange={handleOnGiggerChange}
          noEmptyError
        />
      </Input>
      <Input className="mt-4">
        <Button
          disabled={isDisabled}
          onClick={handleSubmit}
          data-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage id="common_ok" defaultMessage="Ok" />
        </Button>
        <CancelButton
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
          ref={closeModal}
        >
          <FormattedMessage id="common_cancel" defaultMessage="Cancel" />
        </CancelButton>
      </Input>
    </>
  );
};

export default AddGiggersToGroupModal;
