import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Header from '../../../components/Header/Header';
import Tabs from '../../../components/Tabs';
import ProfileTab from './tabs/ProfileTab';
import DocumentTab from './tabs/DocumentTab';
import FeedbackTab from './tabs/FeedbackTab';
import OrdersTab from './tabs/OrdersTab';
import AccountDetails from './tabs/AccountDetails';
import { TabContainer } from './styles';
import { PageName, CurrentPage } from '../../gigger/styles';
import { FlexContainer, Container, Wrapper } from '../users/styles';
import Constants from '../../../shared/constants';
import Modal from './Modal';
import GiggerService from '../../../services/AdminServices/GiggersService';
import Config from '../../../config';
import { MainContext } from '../../../components/Toast/Toast';

import AuthService from '../../../services/AuthService';
import ChangeLanguageModal from '../../../components/Modals/index';

class GiggerDetailView extends Component {
  authService = new AuthService(Config.apiBase);

  giggerService = new GiggerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        Constants.language.common_profile,
        Constants.language.common_orders,
        Constants.language.common_documents,
        Constants.language.common_feedback,
        Constants.language.common_account_details,

      ],
      tab: Constants.language.common_profile,
      isApproveModalOpen: false,
      isAssignModalOpen: false,
      isLabelModalOpen: false,
      isDeleteModalOpen: false,
      isLangModalOpen: false,
      isManageCVModalOpen: false,
      isIdStatusModalOpen: false,
      loggedIn: this.authService.isAuthenticated(),
      giggerData: {},
      loading: false,
      toastData: {},
      showToast: false,
      manageCVData: {},
      cvStatus: '',
      cvData: [],
      isProfileBeingApproved: false,
      isAddGroupModalOpen: false,
      groups: [],
    };
  }

  componentDidMount() {
    const { loggedIn } = this.state;
    if (loggedIn) {
      this.fetchGiggerDetails();
    }
  }

  currentTab = (selectedTab) => {
    this.setState({ tab: selectedTab });
  };

  /**
   * Redirects to gigger list screen
   */
  handleGiggerPage = () => {
    const { history, location: { data = {} } } = this.props;
    const { filterData } = data;
    history.push({ pathname: Constants.routes.giggers.url, data: filterData });
  };

  /**
   * Opens approve profile modal
   */
  handleApproveProfile = () => {
    this.setState({ isApproveModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens delete account confirmation modal
   */
  handleDeleteAccount = () => {
    this.setState({ isDeleteModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens assign owner modal
   */
  handleAssignOwner = () => {
    this.setState({ isAssignModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens add label modal
   */
  handleLabel = () => {
    this.setState({ isLabelModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Opens add id status update modal
   */
  hanldeIdStatus = () => {
    this.setState({ isIdStatusModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * Closes all opened modals
   */
  handleModalClose = () => {
    this.setState({
      isLabelModalOpen: false,
      isApproveModalOpen: false,
      isAssignModalOpen: false,
      isDeleteModalOpen: false,
      isLangModalOpen: false,
      isManageCVModalOpen: false,
      isIdStatusModalOpen: false,
      isAddGroupModalOpen: false,
    });
  };

  /**
   * Opens language modal
   */
  handleLangModal = () => {
    this.setState({ isLangModalOpen: true }, () => {
      this.openLangElement.click();
    });
  };

  handleLogintoapp = () => {
    const { history } = this.props;
    history.push(Constants.routes.adminMyAccount.url);
  };

  handleLogout = () => {
    const { history } = this.props;

    localStorage.clear();
    history.push(Constants.routes.logIn.url);
  };

  /**
   * Loads data after modal is closed
   */
  handleLoad = () => {
    this.handleModalClose();
    this.fetchGiggerDetails();
  };

  /**
   * Handles toaster display,closing,loading data logic.
   * @param {Object} data toaster data
   */
  handleToast = (data) => {
    if (data.type === Constants.toast.SUCCESS) {
      this.fetchGiggerDetails();
    }
    this.setState({ showToast: true, toastData: data });
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, data.delay);
  };

  handleToastClose = () => {
    this.setState({ showToast: false });
  }

  handleManageCV= () => {
    this.setState({ isManageCVModalOpen: true }, () => {
      this.openElement.click();
    });
  }

  /**
   * Handler to set loading state when approval for skeleton loading
   *
   * @param {Boolean} loadingState
   * @memberof GiggerDetailView
   */
  setIsProfileBeingApproved = (loadingState) => {
    this.setState({ isProfileBeingApproved: loadingState });
  }

  stopLoader=() => {
    this.setState({ loading: false });
  }

  handleAddGroup = () => {
    this.setState({ isAddGroupModalOpen: true }, () => {
      this.openElement.click();
    });
  };

  /**
   * @async
   * @function fetchGiggerDetails Fetches the gigger details.
   */
  async fetchGiggerDetails() {
    const { match: { params: { giggername } } } = this.props;
    this.setState({ loading: true });
    try {
      const response = await this.giggerService.getGiggerProfile(giggername, false);
      const { data = {} } = response;
      const {
        _id,
        full_name: fullName = '',
        withoutBankID,
        review,
        status,
        accounts = {},
        personal_number: personalNumber = '',
        organization_number: orgNumber = '',
        third_party_invoice: thirdPartyInvoice = false,
        group,
      } = data;
      const {
        jobtitle = [],
        category = [],
        skills = [],
        systems = [],
        label = '',
        accountOwner = {},
        cv = {},
      } = accounts;
      this.setState({
        loading: false,
        giggerData: {
          full_name: fullName,
          jobtitle,
          category,
          skills,
          systems,
          status,
          labels: label,
          accountOwner: accountOwner && accountOwner.name,
          withoutBankID,
          _id,
          review,
          personalNumber,
          orgNumber,
          thirdPartyInvoice,
        },
        cvStatus: cv && cv.status,
        cvData: cv && cv.comments,
        manageCVData: cv && cv.comments.filter((comnt) => comnt.comment !== null),
        groups: group?.map((o) => (o?.id)) || [],
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      tabs,
      loggedIn,
      tab,
      giggerData,
      isLabelModalOpen,
      isAssignModalOpen,
      isApproveModalOpen,
      isDeleteModalOpen,
      isLangModalOpen,
      isIdStatusModalOpen,
      loading,
      toastData,
      showToast,
      isManageCVModalOpen,
      manageCVData,
      cvStatus,
      isProfileBeingApproved,
      cvData,
      isAddGroupModalOpen,
      groups,
    } = this.state;
    const { match: { params: { giggername } } } = this.props;
    if (!loggedIn) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }
    return (
      <MainContext.Provider value={{ showToast, toastData, onToastClose: this.handleToastClose }}>
        <Header
          loading={loading || isProfileBeingApproved}
          page={Constants.GiggerProfile}
          data={giggerData}
          handleApproveProfile={this.handleApproveProfile}
          handleAssignOwner={this.handleAssignOwner}
          handleLabel={this.handleLabel}
          handleLogintoapp={this.handleLogintoapp}
          handleLogout={this.handleLogout}
          handleDeleteAccount={this.handleDeleteAccount}
          openLangModal={this.handleLangModal}
          ModalClose={this.handleModalClose}
          handleManageCV={this.handleManageCV}
          hanldeIdStatus={this.hanldeIdStatus}
          handleAddGroup={this.handleAddGroup}
        />

        <FlexContainer>
          <Container>
            <Wrapper>
              <PageName className="opacity-7 " onClick={this.handleGiggerPage} role="button">
                <FormattedMessage id="common_giggers" defaultMessage="Giggers" />
                {'  >  '}
              </PageName>
              <span>&nbsp;</span>
              <CurrentPage>{giggerData && giggerData.full_name}</CurrentPage>
            </Wrapper>
            <TabContainer>
              <Tabs tabs={tabs} currentTab={this.currentTab} selectedTab={tab} />
            </TabContainer>
            {tab === Constants.language.common_profile && (
              <ProfileTab
                giggerId={giggername}
                loading={isProfileBeingApproved || loading}
                handleToast={this.handleToast}
                stopLoader={this.stopLoader}
                cvStatus={cvStatus}
              />
            )}
            {tab === Constants.language.common_orders && (
              <OrdersTab giggerId={giggername} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_documents && (
              <DocumentTab giggerId={giggername} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_feedback && (
              <FeedbackTab giggerId={giggername} handleToast={this.handleToast} />
            )}
            {tab === Constants.language.common_account_details && (
            <AccountDetails
              giggerId={giggername}
              handleToast={this.handleToast}
              giggerDetails={giggerData}
            />
            )}
            <a
              href
              data-backdrop="static"
              data-keyboard="false"
              data-toggle="modal"
              data-target="#GiggerModal"
              ref={(openModal) => { this.openElement = openModal; }}
            />
            {isLabelModalOpen && (
              <Modal
                addLabel={isLabelModalOpen}
                handleLoad={this.handleLoad}
                currentLabel={giggerData.labels}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
              />
            )}
            {isAssignModalOpen && (
              <Modal
                assignOwner={isAssignModalOpen}
                handleLoad={this.handleLoad}
                currentAccountOwner={giggerData.accountOwner}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
              />
            )}
            {isApproveModalOpen && (
              <Modal
                approveProfile={isApproveModalOpen}
                handleLoad={this.handleLoad}
                data={giggerData}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
                setIsProfileBeingApproved={this.setIsProfileBeingApproved}
              />
            )}
            {isDeleteModalOpen && (
              <Modal
                remove={isDeleteModalOpen}
                handleLoad={this.handleLoad}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
              />
            )}
            {isManageCVModalOpen && (
              <Modal
                manageCV={isManageCVModalOpen}
                manageCVData={manageCVData}
                status={cvStatus}
                handleLoad={this.handleLoad}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
                cvData={cvData}
              />
            )}
            {isIdStatusModalOpen && (
              <Modal
                idStatus={isIdStatusModalOpen}
                handleLoad={this.handleLoad}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
                accountStatus={giggerData.status}
              />
            )}
            {isAddGroupModalOpen && (
              <Modal
                addGroup={isAddGroupModalOpen}
                handleLoad={this.handleLoad}
                id={giggername}
                ModalClose={this.handleModalClose}
                handleToast={this.handleToast}
                currentGroup={groups}
              />
            )}
          </Container>
          <a
            href
            data-backdrop="static"
            data-keyboard="false"
            data-toggle="modal"
            data-target="#AdminModal"
            ref={(openLangModal) => { this.openLangElement = openLangModal; }}
          />
          {isLangModalOpen && (
            <ChangeLanguageModal
              changeLanguage={isLangModalOpen}
              ModalClose={this.handleModalClose}
            />
          )}
        </FlexContainer>
      </MainContext.Provider>
    );
  }
}

export default GiggerDetailView;
