import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';
import { Redirect } from 'react-router-dom';
import {
  Container,
  Heading,
  AvtarIcon,
  OptionWrapper,
  ProfileWrapper,
  TitleWrapper,
  UserName,
  HeaderIcon,
  Status,
  CheckBox,
  IconDiv,
} from './styles';
import { TickIcon } from '../../styles/Icons';
import Avtar from '../../assets/images/AdminAvtar.svg';
import { Card, Span, Div } from '../Popover/Style';
import Button from '../Button/Button';
import { ButtonWrapper } from '../../pages/admin/giggers/styles';
import { Border } from '../../pages/admin/users/styles';
import GiggerService from '../../services/GiggerService';
import Config from '../../config';
import Constants from '../../shared/constants';
import Loader from '../Loader/Loader';
import Toast, { MainContext } from '../Toast/Toast';
import { RelativeContainer } from '../../styles/CommonStyles';
import CustomerService from '../../services/CustomerService';
import { addHyphenToNumber, isAdmin } from '../../shared/utils';

class Header extends Component {
  giggerService = new GiggerService(Config.apiBase);

  customerService = new CustomerService(Config.apiBase);

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      display: false,
      redirect: false,
      logout: false,
      userName: '',
      loadHeader: false,
    };

    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  async componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick, false);
    this.fetchDetails();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  /**
   * Closes the option block when clicked on outside
   * @param {event} e captures click event
   */
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    const { show, display } = this.state;
    if (this.moreOptions && !this.moreOptions.contains(e.target) && display) {
      this.handleOptions();
    } else if (this.adminOptionsRef && !this.adminOptionsRef.contains(e.target) && show) {
      this.handleDisplayOptions();
    }
  }

  /**
   * Handle show or hide options block
   */
  handleDisplayOptions = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  /**
   * @async
   * @function handleLogout Logout the account and redirects to login page
   */
  handleLogout = async () => {
    const { userData, token, firstLogin } = Constants.localStorageKeys;
    try {
      const response = await this.giggerService.logOut();
      if (response.status === 204 || response.status === 200) {
        [userData, token, firstLogin].forEach((storageKey) => localStorage.removeItem(storageKey));
      }
    } catch (e) {
      // catch error
    }
    [userData, token, firstLogin].forEach((storageKey) => localStorage.removeItem(storageKey));
    this.setState({ logout: true });
  };

  /**
   * Redirects to myaccount pages
   */
  handleLogintoapp= () => {
    this.setState({ redirect: true });
  }

  /**
   * Handle show or hide options block
   */
  handleOptions = () => {
    const { display } = this.state;
    this.setState({ display: !display });
  };

  /**
   * @function handleDisplayStatus
   * @param {string} status Status of the user.
   * @returns Button as per the status passed.
   */
  handleDisplayStatus = (status) => {
    switch (status) {
      case Constants.status.PENDING:
        return <Button name={<FormattedMessage id="common_pending" defaultMessage="Pending" />} type="open" />;
      case Constants.status.APPROVED:
        return <Button name={<FormattedMessage id="common_active" defaultMessage="Active" />} type="approve" />;
      case Constants.status.DRAFT:
        return <Button name={<FormattedMessage id="common_draft" defaultMessage="Draft" />} type="open" />;
      case Constants.status.IDPENDING:
        return <Button name={<FormattedMessage id="common_status_id_pending" defaultMessage="Id pending" />} type="open" />;
      case Constants.status.IDDISAPPROVE:
        return <Button name={<FormattedMessage id="common_status_id_disapprove" defaultMessage="Id disapproved" />} type="cancelled" />;
      default:
        return '';
    }
  };

  toFocusOnDiv=() => () => {
    document.getElementById('clickable-component').focus();
  }

  toFocusOnUserDiv=() => () => {
    document.getElementById('user-options').focus();
  }

  /**
   * @async
   * @function fetchDetails
   * Fetches details of the loggedIn user.
   */
  async fetchDetails() {
    this.setState({ loadHeader: true });
    try {
      const response = await this.customerService.getDetails();
      const { data = {} } = response;
      const { full_name: fullName = Constants.language.common_admin } = data;
      this.setState({ loadHeader: false, userName: fullName });
    } catch (e) {
      this.setState({ loadHeader: false, userName: Constants.language.common_admin });
    }
  }

  render() {
    const {
      show, display, redirect, logout, userName, loadHeader,
    } = this.state;
    const {
      name,
      data,
      page,
      loading,
      listScreen,
      filter,
      users,
      groups,
      handleEditUser,
      handleOpenModal,
      handleApproveProfile,
      handleAssignOwner,
      handleDeleteAccount,
      handleLock,
      handleAddUser,
      handleManageCV,
      hanldeIdStatus,
      handleAddGroup,
      groupDetails,
      handleDeleteGroup,
      handleRenameGroup,
      handleAddMembersToGroup,
    } = this.props;

    const { showToast = false } = this.context;

    if (redirect) {
      return <Redirect to={Constants.routes.adminMyAccount.url} />;
    }
    if (logout) {
      return <Redirect to={Constants.routes.logIn.url} />;
    }

    return (
      <RelativeContainer>
        {showToast && (
        <Toast
          position="fixed"
        />
        )}
        <Container className="row m-0">
          <TitleWrapper className="col p-0">
            <div className="row m-0">
              <div className="col-auto p-0 mr-3">
                {!loading && (data
                  ? <Heading>{data.company_name ? data.company_name : data.full_name}</Heading>
                  : <Heading>{name}</Heading>
                )}
              </div>
              <IconDiv className="col p-0">
                {!listScreen && page === Constants.GiggerProfile && (
                  <HeaderIcon
                    ref={(moreOptions) => {
                      this.moreOptions = moreOptions;
                    }}
                    src={Constants.icons.ThreeDots}
                    alt="icon"
                    role="button"
                    onClick={this.handleOptions}
                  />
                )}
                {!listScreen && page !== Constants.GiggerProfile && (
                  <HeaderIcon
                    ref={(moreOptions) => {
                      this.moreOptions = moreOptions;
                    }}
                    src={Constants.icons.ThreeDots}
                    alt="icon"
                    role="button"
                    onClick={this.handleOptions}
                  />
                )}
                {display && users && (
                  <OptionWrapper>
                    <Card
                      onBlur={this.handleOptions}
                      tabIndex="-1"
                      id="user-options"
                      onLoad={this.toFocusOnUserDiv}
                    >
                      <>
                        <Div role="button" onClick={handleEditUser}>
                          <Span>
                            <FormattedMessage id="admin_header_add_new_user" defaultMessage="Add new user" />
                          </Span>
                        </Div>
                      </>
                    </Card>
                  </OptionWrapper>
                )}
                {display && groups && (
                  <OptionWrapper>
                    <Card
                      onBlur={this.handleOptions}
                      tabIndex="-1"
                      id="user-options"
                      onLoad={this.toFocusOnUserDiv}
                    >
                      <>
                        <Div role="button" onClick={handleAddGroup}>
                          <Span>
                            <FormattedMessage id="admin_header_add_new_group" defaultMessage="Add new group" />
                          </Span>
                        </Div>
                      </>
                    </Card>
                  </OptionWrapper>
                )}

                {display && groupDetails && (
                <OptionWrapper>
                  <Card
                    onBlur={this.handleOptions}
                    tabIndex="-1"
                    id="user-options"
                    onLoad={this.toFocusOnUserDiv}
                  >
                    <>
                      <Div role="button" onClick={handleAddMembersToGroup}>
                        <Span>
                          <FormattedMessage id="group_add_members" defaultMessage="Add members" />
                        </Span>
                      </Div>
                      <Border />
                      <Div role="button" onClick={handleRenameGroup}>
                        <Span>
                          <FormattedMessage id="group_rename" defaultMessage="Rename" />
                        </Span>
                      </Div>
                      <Border />
                      <Div role="button" onClick={handleDeleteGroup}>
                        <Span>
                          <FormattedMessage id="delete_group" defaultMessage="delete group" />
                        </Span>
                      </Div>
                    </>
                  </Card>
                </OptionWrapper>
                )}
                {display && filter && (
                  <OptionWrapper>
                    <Card
                      onBlur={this.handleOptions}
                      tabIndex="-1"
                      id="user-options"
                      onLoad={this.toFocusOnUserDiv}
                    >
                      <>
                        <Div role="button" onClick={handleOpenModal}>
                          <Span>
                            <FormattedMessage id="header_add_new_filter" defaultMessage="Add new filter" />
                          </Span>
                        </Div>
                      </>
                    </Card>
                  </OptionWrapper>
                )}
                {loading && <Loader width="250px" height="32px" />}
                {!loading && display && page === Constants.GiggerProfile && (
                  <OptionWrapper>
                    <Card
                      onBlur={this.handleOptions}
                      tabIndex="-1"
                      id="user-options"
                      onLoad={this.toFocusOnUserDiv}
                    >
                      <>
                        {isAdmin()
                       && (
                       <>
                         <Div
                           role="button"
                           onClick={handleApproveProfile}
                           className={data && (data.status === Constants.status.PENDING) ? '' : 'disableOption'}
                         >
                           <Span>
                             <FormattedMessage id="admin_header_approve_profile" defaultMessage="Approve profile" />
                           </Span>
                         </Div>
                         <Border />
                         <Div
                           role="button"
                           onClick={hanldeIdStatus}
                           className={data && ((data.status !== Constants.status.IDDISAPPROVE && data.status !== Constants.status.IDPENDING) || !data.withoutBankID) ? 'disableOption' : ''}
                         >
                           <Span>
                             <FormattedMessage id="common_id_status" defaultMessage="Id status" />
                           </Span>
                         </Div>
                         <Border />
                         <Div role="button" onClick={handleAssignOwner} className={data && ((data.status === Constants.status.IDDISAPPROVE || data.status === Constants.status.IDPENDING) || !data.review) ? 'disableOption' : ''}>
                           <Span>
                             <FormattedMessage
                               id="admin_header_assign_account_owner"
                               defaultMessage="Assign account owner"
                             />
                           </Span>
                         </Div>
                         <Border />
                       </>
                       )}
                        <Div role="button" onClick={handleManageCV}>
                          <Span>
                            <FormattedMessage
                              id="admin_header_manage_cv"
                              defaultMessage="Manage CV"
                            />
                          </Span>
                        </Div>
                        <Border />
                        {isAdmin()
                       && (
                       <>
                         <Div role="button" onClick={handleAddGroup}>
                           <Span>
                             <FormattedMessage
                               id="label_add_to_group"
                               defaultMessage="Add to group"
                             />
                           </Span>
                         </Div>
                         <Border />
                         <Div role="button" onClick={handleDeleteAccount}>
                           <Span>
                             <FormattedMessage id="common_delete_account" defaultMessage="Delete account" />
                           </Span>
                         </Div>
                       </>
                       )}
                      </>
                    </Card>
                  </OptionWrapper>
                )}
                {!loading && display && page === Constants.CompanyProfile && (
                  <OptionWrapper>
                    <Card
                      onBlur={this.handleOptions}
                      tabIndex="-1"
                      id="user-options"
                      onLoad={this.toFocusOnUserDiv}
                    >
                      <>
                        <Div
                          role="button"
                          onClick={handleAddUser}
                        >
                          <Span>
                            <FormattedMessage id="common_add_user" defaultMessage="Add user" />
                          </Span>
                        </Div>
                        <Border />
                        <Div
                          role="button"
                          onClick={handleApproveProfile}
                          className={data && data.status === Constants.status.APPROVED ? 'disableOption' : ''}
                        >
                          <Span>
                            <FormattedMessage id="admin_header_approve_profile" defaultMessage="Approve profile" />
                          </Span>
                        </Div>
                        <Border />
                        <Div role="button" onClick={handleAssignOwner}>
                          <Span>
                            <FormattedMessage
                              id="admin_header_assign_account_owner"
                              defaultMessage="Assign account owner"
                            />
                          </Span>
                        </Div>
                        <Border />
                        <Div role="button" onClick={handleLock}>
                          <Span>
                            <FormattedMessage id="common_lock" defaultMessage="Lock" />
                          </Span>
                        </Div>
                        <Border />
                        <Div role="button" onClick={handleDeleteAccount}>
                          <Span>
                            <FormattedMessage id="common_delete_account" defaultMessage="Delete account" />
                          </Span>
                        </Div>
                      </>
                    </Card>
                  </OptionWrapper>
                )}
              </IconDiv>
            </div>
            {!loading && page === Constants.CompanyProfile && (
              <>
                <div className="row m-0">
                  <Status>
                    {' '}
                    <FormattedMessage id="common_orginisation_number" defaultMessage="Org. number" />
                    :
                    {' '}
                    {addHyphenToNumber(data.organization_number, 6)}
                  </Status>
                </div>

                <div className="row m-0">
                  {data && data.withoutBankID ? (
                    <Status>
                      <FormattedMessage
                        id="admin_header_signed_up_wo_bankid"
                        defaultMessage="Signed up without BankId"
                      />
                    </Status>
                  ) : (
                    <>
                      <CheckBox>
                        <TickIcon size={16} fill="#fff" />
                      </CheckBox>
                      {' '}
                      <Status className="ml-2 mb-0">
                        <FormattedMessage id="header_verified_with_bankid" defaultMessage="Verified with BankId" />
                      </Status>
                    </>
                  )}
                  {data && data.accountOwner ? (
                    <Status>
                      <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                      :
                      {' '}
                      {data.accountOwner.name}
                    </Status>
                  ) : (
                    <Status>
                      <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                      :
                      {' '}
                      {Constants.language.common_none}
                    </Status>
                  )}
                  <ButtonWrapper>
                    {this.handleDisplayStatus(data && data.status)}
                  </ButtonWrapper>
                </div>
                {data && data.limit && (
                <div className="row m-0">
                  <Status>
                    <FormattedMessage id="label_limited_account" defaultMessage="Limited account" />
                  </Status>
                </div>
                )}
              </>
            )}
            {!loading && page === Constants.GiggerProfile && Object.keys(data).length > 0 && (
              <>
                <div className="row m-0">
                  <Status>
                    {' '}
                    <FormattedMessage id="common_personal_number" defaultMessage="Personal number" />
                    :
                    {' '}
                    {addHyphenToNumber(data.personalNumber, 8)}
                  </Status>
                </div>
                <div className="row m-0">
                  {data && data.withoutBankID ? (
                    <Status>
                      <FormattedMessage id="admin_header_signed_up_wo_bankid" defaultMessage="Signed up without BankId" />
                    </Status>
                  ) : (
                    <>
                      <CheckBox>
                        <TickIcon size={16} fill="#fff" />
                      </CheckBox>
                      {' '}
                      <Status className="ml-2 mb-0">
                        <FormattedMessage id="admin_header_verified_with_bankid" defaultMessage="Verified with BankId" />
                      </Status>
                    </>
                  )}
                  {data && data.accountOwner ? (
                    <Status>
                      <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                      :
                      {' '}
                      {data.accountOwner}
                    </Status>
                  ) : (
                    <Status>
                      <FormattedMessage id="common_account_owner" defaultMessage="Account owner" />
                      :
                      {' '}
                      <FormattedMessage id="common_none" defaultMessage="None" />
                    </Status>
                  )}
                  <ButtonWrapper>
                    {data
                    && data.review === false ? (
                      <Button name={<FormattedMessage id="common_draft" defaultMessage="Draft" />} type="open" />
                      ) : (
                        <>
                          {this.handleDisplayStatus(data.status)}
                        </>
                      )}
                  </ButtonWrapper>
                </div>
              </>
            )}
          </TitleWrapper>
          <ProfileWrapper
            ref={(adminOptionsRef) => {
              this.adminOptionsRef = adminOptionsRef;
            }}
            className="col-auto"
            onClick={this.handleDisplayOptions}
            role="button"
          >
            {!loadHeader && <AvtarIcon src={Avtar} alt="icon" />}
            {' '}
            {loadHeader && <Loader width="70px" />}
            {!loadHeader && (
            <UserName>
              {userName}
            </UserName>
            )}
            {show && (
              <Card
                onBlur={this.handleDisplayOptions}
                tabIndex="-1"
                id="clickable-component"
                onLoad={this.toFocusOnDiv}
              >
                <>
                  <Button
                    name={<FormattedMessage id="admin_header_change_to_app" defaultMessage="Change to app" />}
                    onClick={this.handleLogintoapp}
                    className="fs-14 text-left"
                  />
                  <Border />
                  <Button
                    name={<FormattedMessage id="common_log_out" defaultMessage="Log out" />}
                    onClick={this.handleLogout}
                    className="fs-14 text-left"
                  />
                </>
              </Card>
            )}
          </ProfileWrapper>
        </Container>
      </RelativeContainer>
    );
  }
}

export default Header;

Header.propTypes = {
  name: PropTypes.string,
  page: PropTypes.number,
  loading: PropTypes.bool,
  listScreen: PropTypes.bool,
  filter: PropTypes.bool,
  users: PropTypes.bool,
};

Header.defaultProps = {
  name: 'Gigassembly',
  page: 1,
  loading: false,
  listScreen: false,
  filter: false,
  users: false,
};
Header.contextType = MainContext;
