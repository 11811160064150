import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Constants from '../../shared/constants';
import {
  P, Div, Img, Ul, Li,
} from './styles';
import { isAdmin, isCvVerificationUser } from '../../shared/utils';
import Config from '../../config';

class Sidemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  isActive = (route) => (window.location.pathname.search(route) >= 0 ? 'active' : '');

  render() {
    let language = localStorage.getItem('language');

    if (!language) {
      language = Config.language;
      localStorage.setItem('language', language);
    }

    return (
      <Div className="sidebar mh-100 h-100" id="sidebar">
        <Div className="my-3 mx-5 py-3">
          <a
            href={`${Config.wordpressUrl}/${language}/${Constants.wpURL[language].home}`}
            aria-label="gigassembly"
            target="_self"
          >
            <Img
              src={Constants.icons.LogoGold}
              alt="logo"
              className="cursor-pointer dashboardLogo"
            />
          </a>
        </Div>
        <Div className="menuContainer">
          <Ul className="wrapper p-0 m-0">
            {(isAdmin() || isCvVerificationUser())
            && (
            <Li className={this.isActive(Constants.routes.gigger.url)}>
              <Link to={Constants.routes.giggers.url}>
                <P className="menuItems">
                  <FormattedMessage id="common_giggers" defaultMessage="Giggers" />
                </P>
              </Link>
            </Li>
            )}
            {isAdmin() && (
              <>
                <Li className={this.isActive(Constants.routes.groups.url)}>
                  <Link to={Constants.routes.groups.url}>
                    <P>
                      <FormattedMessage id="groups" defaultMessage="Groups" />
                    </P>
                  </Link>
                </Li>
                <Li className={this.isActive(Constants.routes.company.url)}>
                  <Link to={Constants.routes.companies.url}>
                    <P>
                      <FormattedMessage id="common_company" defaultMessage="Company" />
                    </P>
                  </Link>
                </Li>

                <Li className={this.isActive(Constants.routes.orders.url)}>
                  <Link to={Constants.routes.orders.url}>
                    <P>
                      <FormattedMessage id="common_orders" defaultMessage="Orders" />
                    </P>
                  </Link>
                </Li>

                <Li className={this.isActive(Constants.routes.feedback.url)}>
                  <Link to={Constants.routes.feedback.url}>
                    <P>
                      <FormattedMessage id="common_feedback" defaultMessage="Feedback" />
                    </P>
                  </Link>
                </Li>

                <Li className={this.isActive(Constants.routes.filter.url)}>
                  <Link to={Constants.routes.filter.url}>
                    <P>
                      <FormattedMessage id="common_filter" defaultMessage="Filter" />
                    </P>
                  </Link>
                </Li>

                <Li className={this.isActive(Constants.routes.users.url)}>
                  <Link to={Constants.routes.users.url}>
                    <P>
                      <FormattedMessage id="common_users" defaultMessage="Users" />
                    </P>
                  </Link>
                </Li>
              </>
            )}
          </Ul>
        </Div>
      </Div>
    );
  }
}
export default withRouter(Sidemenu);
