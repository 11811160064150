import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Constants from '../../../shared/constants';
import DoubleRangeSlider from '../../../components/Input/DoubleRangeSlider';
import CustomDatePicker from '../../../components/CustomCalender/CustomDatePicker';
import Button from '../../../components/Button/Button';
import { Label } from '../../../components/Input/styles';
import { GreyCard } from '../../gigger/styles';

const GroupFilter = ({ fetchGroupDetails }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [hourlyRateMin, setHourlyRateMin] = useState(Constants.slider.min);
  const [hourlyRateMax, setHourlyRateMax] = useState(Constants.slider.max);
  const [filterData, setFilterData] = useState([
    { name: 'availabledate', label: '', id: '' },
    { name: 'rate', label: '', id: '' },
  ]);
  const [isFiltersApplied, setFiltersApplied] = useState(false);

  const handleDateChange = (type) => (name, value) => {
    const { INVALID_DATE } = Constants;
    const startDateValue = type === 'start' ? value : startDate;
    const endDateValue = type === 'end' ? value : endDate;

    const data = filterData.map((obj) => {
      if (obj.name === 'availabledate' && moment(value).format(Constants.yearMonthDateFormat) !== INVALID_DATE) {
        const date = startDateValue && endDateValue ? `${startDateValue},${endDateValue}` : startDateValue || (endDateValue ? `,${endDateValue}` : '');
        return { ...obj, id: date, label: 'available_date' };
      }
      return obj;
    });

    if (moment(value).format(Constants.yearMonthDateFormat) !== INVALID_DATE) {
      setStartDate(startDateValue);
      setEndDate(endDateValue);
      setFilterData(data);
    }
    setFiltersApplied(false);
  };

  const sliderChange = (min, max) => {
    const data = filterData.map((obj) => {
      if (obj.name === 'rate') {
        return { ...obj, id: `${min},${max}`, label: 'hourly_rate' };
      }
      return obj;
    });

    setFilterData(data);
    setHourlyRateMin(min);
    setHourlyRateMax(max);
    setFiltersApplied(false);
  };

  const handleChange = (name, label) => {
    const data = filterData.map((obj) => {
      if (obj.name === name) {
        return ({ ...obj, id: label, label });
      }
      return obj;
    });
    setFilterData(data);
    setFiltersApplied(false);
  };

  const clearFilter = () => {
    setFilterData([
      { name: 'availabledate', label: '', id: '' },
      { name: 'rate', label: '', id: '' },
    ]);
    setHourlyRateMin(Constants.slider.min);
    setHourlyRateMax(Constants.slider.max);
    setStartDate('');
    setEndDate('');
    setFiltersApplied(false);
    fetchGroupDetails();
  };

  const handleApplyFilter = () => {
    const params = [];
    filterData.map((obj) => {
      if (obj.id) {
        params.push([obj.name, obj.id]);
      }
      return obj;
    });
    fetchGroupDetails(params);
    setFiltersApplied(true);
  };

  return (
    <>
      <GreyCard>
        <div className="row m-0">
          <form className="p-3 overflow-hidden" autoComplete="off" onSubmit={(e) => { e.preventDefault(); }}>
            <Label forFilter>
              <FormattedMessage id="common_availability" defaultMessage="Availability" />
            </Label>
            <CustomDatePicker
              className="mb-4"
              forFilter
              label={<FormattedMessage id="common_start" defaultMessage="Start" />}
              value={startDate}
              type="date"
              placeholder="YYYY-MM-DD"
              name="from"
              existError={endDate < startDate && endDate !== ''}
              existErrorMessage={(
                <FormattedMessage
                  id="error_message_from_date_is_greater"
                  defaultMessage="From date shouldn't be greater than to date"
                />
                )}
              onChange={handleDateChange('start')}
              noEmptyError
            />
            <CustomDatePicker
              className="mb-4"
              forFilter
              label={<FormattedMessage id="common_end" defaultMessage="End" />}
              type="date"
              placeholder="YYYY-MM-DD"
              value={endDate}
              name="to"
              onChange={handleDateChange('end')}
              errorMessage={<FormattedMessage id="error_end_date_message" defaultMessage="Enter end date" />}
              noEmptyError
            />
            <DoubleRangeSlider
              forFilter
              className="mb-4"
              label={<FormattedMessage id="gigger_filter_hourly_rate" defaultMessage="Hourly Rate" />}
              min={Constants.slider.min}
              max={Constants.slider.max}
              minValue={hourlyRateMin}
              maxValue={hourlyRateMax}
              sliderChange={sliderChange}
              onChange={handleChange}
              isPortal
            />
            <Button
              name={isFiltersApplied
                ? <FormattedMessage id="gigger_filter_clear_all" defaultMessage="Clear all" />
                : <FormattedMessage id="filter_show_results" defaultMessage="Show results" />}
              onClick={isFiltersApplied ? clearFilter : handleApplyFilter}
              type="cancel"
              className="d-lg-block d-xl-block d-none"
            />
          </form>
        </div>
      </GreyCard>
    </>
  );
};

export default GroupFilter;
