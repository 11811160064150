import ApiService from '../ApiService';

class GroupsService extends ApiService {
  endPoint = {
    groups: 'admin/groups',
    createGroup: 'admin/groups/create',
    updategroup: 'admin/groups/update',
  };

  async getGroupsList(queryParam) {
    const response = await this.get(this.baseURL
      + this.endPoint.groups + this.queryParamString(queryParam));
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async getGroupDetailsById(id, params) {
    const response = await this.get(`${this.baseURL
      + this.endPoint.groups}/${id}${this.queryParamString(params)}`);
    if (response.status !== 200) {
      throw Error(response.statusText);
    } else {
      return response.data;
    }
  }

  async createGroup(data) {
    const response = await this.post(this.baseURL + this.endPoint.createGroup, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }

  async deleteGroup(id) {
    const response = await this.delete(`${this.baseURL + this.endPoint.groups}/${id}`);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }

  async updateGroupDetails(id, data) {
    const response = await this.put(`${this.baseURL + this.endPoint.updategroup}/${id}`, data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
    throw Error(response.statusText);
  }
}
export default GroupsService;
